import React, {useState} from "react";
import '../Header.css';
import {
    Col,
    Row,
    Form,
    ButtonGroup,
    ToggleButton,
    Modal,
    Button,
    Container
} from 'react-bootstrap';



function HeaderMenu({activeParams,toggleParams, ...props}) {

    const [radioValue, setRadioValue] = useState('3');
    const radios = [
        {name: 'Сьогодні', value: '1'},
        {name: 'Вчора', value: '2'},
        {name: 'Тиждень', value: '3'},
        {name: 'Місяць', value: '4'},
    ];

    return (
        <Modal dialogClassName={"header_menu"} centered show={activeParams} onHide={() => toggleParams(false)}>
            <Modal.Header closeButton>
                <Container>
                    <Modal.Title>Налаштування</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h5>
                                Налаштування швидкого треку:
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ButtonGroup className="mb-2">
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        className={"radio"}
                                        key={idx}
                                        size={"sm"}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant='outline-primary'
                                        name="radio"
                                        value={radio.value}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="params_select">
                        <Col xs={12}>
                            <h5>Параметри звітів:</h5>
                        </Col>
                        <Row>
                            <Col xs={6}>
                                <h6> Формат дати:</h6>
                            </Col>
                            <Col xs={6}>
                                <Form.Select size="sm">
                                    <option>yyyy-mm-dd</option>
                                    <option>yyyy-mm-dd</option>
                                    <option>yyyy-mm-dd</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <h6>Формат часу:</h6>
                            </Col>
                            <Col xs={6}>
                                <Form.Select size="sm">
                                    <option>hh:mm:ss</option>
                                    <option>hh:mm:ss</option>
                                    <option>hh:mm:ss</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <h6> Шрифт:</h6>
                            </Col>
                            <Col xs={6}>
                                <Form.Select size="sm" >
                                    <option>Inter</option>
                                    <option>Inter</option>
                                    <option>Inter</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <h6> Формат координат:</h6>
                            </Col>
                            <Col xs={6}>
                                <Form.Select size="sm">
                                    <option>Широта, Довгота</option>
                                    <option>Довгота, Широта</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col xs={12} className={"model_button"} >
                            <Button  variant="primary" onClick={() => toggleParams(false)}>
                                Скасувати
                            </Button>
                            <Button variant="primary" onClick={() => toggleParams(false)}>
                                Зберегти
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

        </Modal>
    );
}


export default HeaderMenu

