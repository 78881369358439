import React, {useState} from "react";
import '../map.css'
import {Marker, Tooltip, useMapEvents} from "react-leaflet";
import {Icon} from "leaflet";
import mrIcon from "../../../../../assets/icons/map-marker.png";


function MarkerOutMap({map, zoomLevel, handleOnFlyTo, tr, ...props}) {


    const [show, toggleShow] = useState(false)
    const [position, setPosition] = useState({
        lat: tr.point.coordinates[1],
        lng: tr.point.coordinates[0]
    });


    function onZoomCorrect() {
        let firstZoom = 3
        let num = zoomLevel
        let zoomCorrect=2.4
        for(num; num > firstZoom; num = num-1){
            zoomCorrect=zoomCorrect/2

        }
        return zoomCorrect


    }

    function onBounds() {
        let bounds = [map.getBounds().getSouthWest(), map.getBounds().getNorthEast()]
        if ((bounds[0].lat < tr.point.coordinates[1] && tr.point.coordinates[1] < bounds[1].lat) && (bounds[0].lng < tr.point.coordinates[0] && tr.point.coordinates[0] < bounds[1].lng)) {
            toggleShow(false)
        } else if (bounds[0].lat > tr.point.coordinates[1]) {
            toggleShow(true)
            if (bounds[0].lng > tr.point.coordinates[0]) {
                setPosition({
                    ...position,    
                    lat: bounds[0].lat+onZoomCorrect(),
                    lng: bounds[0].lng+onZoomCorrect()
                })

            } else if (bounds[1].lng < tr.point.coordinates[0]) {
                setPosition({
                    ...position,
                    lat: bounds[0].lat+onZoomCorrect(),
                    lng: bounds[1].lng-onZoomCorrect()

                })
            } else setPosition({
                ...position,
                lng: tr.point.coordinates[0],
                lat: bounds[0].lat +onZoomCorrect(),
            })
        } else if (bounds[1].lat < tr.point.coordinates[1]) {
            toggleShow(true)
            if (bounds[0].lng > tr.point.coordinates[0]) {
                setPosition({
                    ...position,
                    lat: bounds[1].lat-onZoomCorrect(),
                    lng: bounds[0].lng+onZoomCorrect()
                })

            } else if (bounds[1].lng < tr.point.coordinates[0]) {
                setPosition({
                    ...position,
                    lat: bounds[1].lat-onZoomCorrect(),
                    lng: bounds[1].lng-onZoomCorrect()
                })

            } else setPosition({
                ...position,
                lat: bounds[1].lat-onZoomCorrect(),
                lng: tr.point.coordinates[0]
            })
        } else if (bounds[0].lng > tr.point.coordinates[0]) {
            toggleShow(true)
            setPosition({
                ...position,
                lng: bounds[0].lng+onZoomCorrect(),
                lat: tr.point.coordinates[1]
            })
        } else if (bounds[1].lng < tr.point.coordinates[0]) {
            toggleShow(true)
            setPosition({
                ...position,
                lng: bounds[1].lng-onZoomCorrect(),
                lat: tr.point.coordinates[1]
            })

        }
    }


    const mapEvents = useMapEvents({
        move: () => {
            onBounds()
        },

    });

    return show && <Marker position={position} eventHandlers={{
        click: () => {
            handleOnFlyTo(tr.point.coordinates)
        }
    }} icon={new Icon({
        iconUrl: mrIcon,
        iconSize: [41, 41],
        className: "ddd"
    })}>

        <Tooltip sticky direction="auto">
            {tr.name}
        </Tooltip>
    </Marker>

}

export default MarkerOutMap;
