import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from 'react-bootstrap';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import LoginContainer from "./Components/LoginPage/LoginContainer";
import MonitoringPageContainer from "./Components/MonitoringPage/MonitoringPageContainer";
import HeaderContainer from "./Components/Header/HeaderContainer";
import ReportsPageContainer from "./Components/ReportsPage/ReportsPageContainer";
import TrackPageContainer from "./Components/TrackPage/TrackPageContainer";

function App(props) {
    return (
            <BrowserRouter>
                <Container fluid className="px-0">
                  <HeaderContainer />
                    <Routes>
                    <Route path="/" element={<MonitoringPageContainer status={props.status} />}/>
                    <Route path="/reports" element={<ReportsPageContainer status={props.status} />} />
                    <Route path="/track" element={<TrackPageContainer status={props.status} />} />
                    <Route path="/login" element={<LoginContainer />} />
                    </Routes>
                </Container>
            </BrowserRouter>
    );
}



export default App
