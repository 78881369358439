import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import '../map.css'

function LeafletgeoSearch(props) {
    const map = useMap();
    useEffect(() => {
        const provider = new OpenStreetMapProvider({
            params: {
                countrycodes: "ua", // limit search results to the Netherlands
                addressdetails: 1 // include additional address detail parts
            }
        });

        const searchControl = new GeoSearchControl({
            provider,
            ...props
        });

        map.addControl(searchControl);

        return () => map.removeControl(searchControl);
    }, []);

    return null;
}
export default LeafletgeoSearch;

