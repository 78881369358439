import React, {useEffect, useState} from "react";
import '../../../Toolbar.css';
import {
    Col,
    Row,
    Modal,
    Button,
    Container, InputGroup, FormControl
} from 'react-bootstrap';
import MenuAccordionTrack from "../MenuAccordionTrack/MenuAccordionTrack";
import SearchBlock from "../../../../../../HelpFunction/SearchBlock";


function AddTrackersMenu({groupUid, addTrackers, toggleAddTrackers, clearSelectTrack, ...props}) {


    const [showTrack, toggleShowTrack] = useState([...props.track]);


    let track = showTrack.map(tr => {
        if (groupUid !== tr.device_group_uid) {
            return <MenuAccordionTrack isSetGroupForTrack={props.isSetGroupForTrack} key={tr.uid} tr={tr}
                                       isDeleteBtn={false} addSelectTrack={props.addSelectTrack}
                                       delSelectTrack={props.delSelectTrack}
                                       selectTrack={props.selectTrack}
            />
        } else return null
    })

    return (
        <Modal dialogClassName={"change_group_menu"} centered show={addTrackers}
               onHide={() => toggleAddTrackers(false)}>
            <Modal.Header>
                <Container>
                    <Modal.Title>Додати трекери</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container className={"group_menu_body"}>
                    <Row>
                        <Col xs={12}>
                                <SearchBlock showTrack={showTrack} toggleShowTrack={toggleShowTrack}
                                             fullList={props.track} placeholder={"Пошук трекера"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={"group_space"}>
                            <ul className={"menu_track_list"}>
                                {track}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"btn_tooltip_position"} xs={6}></Col>
                        <Col className={"btn_tooltip_position"} xs={6}>
                            <Button className={"btn_tooltip_menu left"} variant="primary" onClick={() => {
                                toggleAddTrackers(false)
                                clearSelectTrack()
                            }}>
                                Скасувати
                            </Button>
                            <Button onClick={() => {
                                props.setGroupTrack(groupUid)
                                toggleAddTrackers(false)
                            }} className={"btn_tooltip_menu"} variant="primary">
                                Зберегти
                            </Button></Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}


export default AddTrackersMenu

