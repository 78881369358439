import {useEffect, useState} from 'react';
import {useMap, useMapEvents} from 'react-leaflet';
import * as L from 'leaflet'
import 'leaflet.zoomslider'
import '../map.css'


function SliderZoom({onBounds, setZoomLevel,zoomLevel, ...props}) {

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });


    const map = useMap();
    useEffect(() => {


        const sliderControl = new L.Control.Zoomslider()



        map.addControl(sliderControl);

        return () => map.removeControl(sliderControl);
    }, []);

    return null;
}
export default SliderZoom;

