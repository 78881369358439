import {dozorAPI} from "../API/Api";

const SET_USER_DATA = 'SET_USER_DATA'
const LOGOUT = 'LOGOUT'


let initialState = {
    error_message: undefined,
    status:undefined,
    user:undefined,
    key:undefined,
}


const loginReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_USER_DATA:
            return {
                ...state,
                user: action.data.user,
                error_message: action.data.error_message,
                status: action.data.status,
                key: action.data.key
            }
            case LOGOUT:
            return {
                ...state,
                user: undefined,
                error_message: undefined,
                status: undefined,
                key: undefined
            }

        default:
            return state;
    }
}


export const setAuthUserData = (user) => ({type: SET_USER_DATA, data: user})
export const logout = () => ({type: LOGOUT})


export const getAuthUserData = (login) => {
    return async (dispatch) => {
        let response = await dozorAPI.authUser(login)
             dispatch(setAuthUserData(response.data));
        }
}

export default loginReducer;