import React, {useState} from "react";
import '../../../Toolbar.css';
import {Form} from "react-bootstrap";
import btnDelete from "../../../../../../../assets/icons/X.svg";

function MenuAccordionTrack({tr,isSetGroupForTrack, isDeleteBtn,addSelectTrack,delSelectTrack, ...props}) {



    const [checkTrack, toggleCheck] = useState(false);

    const handleMain = (e) => {
        toggleCheck(e.target.checked);
        e.target.checked === true ? addSelectTrack(tr) : delSelectTrack(tr)
    };

    return (
        <li>

            <div className={"menu_track_checkbox"}><Form.Check
                             type="checkbox"
                             checked={checkTrack}
                             onChange={handleMain}
                             id={`MenuAccordionTrack${tr.uid}`}/></div>
            <div className={"menu_track_name"}>
            <h6 onClick={()=>{console.log(props.selectTrack)}} title={tr.name}>{tr.name}</h6>
            </div>
            {isDeleteBtn && <div><img src={btnDelete} alt={"btnDelete"} onClick={()=>{isSetGroupForTrack(tr, "")}}/></div>}
        </li>
    )
}


export default MenuAccordionTrack;
