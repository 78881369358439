

const SET_TOOLTIP_CHECKBOX = 'SET_TOOLTIP_CHECKBOX'
const TOGGLE_HIDDEN_COLS = 'TOGGLE_HIDDEN_COLS'
const LOGOUT_PARAMS = 'LOGOUT_PARAMS'



let initialState = {
    tooltipCheckbox: {
        trackTime: true,
        serverTime: true,
        satellite: true,
        speed: true,
        sensorStatus: true,
        address: true,
    },
    hiddenCols: []
}


const paramsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TOOLTIP_CHECKBOX:
            return {
                ...state,
                tooltipCheckbox: action.payload
            }
        case TOGGLE_HIDDEN_COLS:
            if(!state.hiddenCols.map(x => x).includes(action.payload)){
            return {
                ...state,
                hiddenCols:  [...state.hiddenCols, action.payload]
            }
            }else {
                return {
                    ...state,
                    hiddenCols:  [...state.hiddenCols.filter(item => item !== action.payload)]
                }
            }
        case LOGOUT_PARAMS:
            return {
                ...state,
                hiddenCols:[],
                tooltipCheckbox:{
                    trackTime: true,
                    serverTime: true,
                    satellite: true,
                    speed: true,
                    sensorStatus: true,
                    address: true,
                }
            }
        default:
            return state;
    }
}


export const setTooltipCheckbox = (data) => ({type: SET_TOOLTIP_CHECKBOX, payload: data})
export const toggleHiddenCols = (data) => ({type: TOGGLE_HIDDEN_COLS, payload: data})
export const logoutParams = () => ({type: LOGOUT_PARAMS})



export default paramsReducer;