import React from "react";
import {connect} from "react-redux";
import MonitoringPage from "./MonitoringMap/MonitoringMap";
import {
    allATLocation,
    allATTooltip,
    getGroupData,
    getLocationData,
    getStreetsData,
    getTrackData, setGroupForTrack, toggleATLocation,
    toggleATTooltip,
    toggleStart
} from "../../Redux/monitoringReducer";
import {Navigate} from "react-router-dom";
import MonitoringTable from "./MonitoringTable/MonitoringTable";
import {delHiddenCols, addHiddenCols, toggleHiddenCols} from "../../Redux/paramsReducer";


class MonitoringPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTable: false,
            showAllLocation: false,
            showAllTooltip: false,
            selectTrack: []
        };
    }


    async componentDidMount() {
        if (this.props.status === "OK") {
            await this.props.getGroupData(this.props.mainKey)
            await this.props.getTrackData(this.props.mainKey)
            await this.props.getLocationData(this.props.mainKey)
            await this.props.toggleStart()
            // for (const element of this.props.location) {
            //     await this.props.getStreetsData(element.point.coordinates, element.device_uid);
            // }


        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isStart !== this.props.isStart) {
            if (this.props.isStart === true) {
                this.timerID = setInterval(() => {
                    this.props.getTrackData(this.props.mainKey).then(() =>
                        this.props.getLocationData(this.props.mainKey))
                    //     .then(() => {
                    // for (const element of this.props.location) {
                    //     let old = prevProps.location.find(x => x.device_uid === element.device_uid).point.coordinates
                    //     if (element.point.coordinates !== old) {
                    //         this.props.getStreetsData(element.point.coordinates, element.device_uid)
                    //     }
                    // }})

                    console.log("request" + new Date())
                }, 15000);
            } else {
                clearInterval(this.timerID)
            }

        }


// *******Відобразити на карті та тултип*******
        if (prevProps.activeMapTrack !== this.props.activeMapTrack) {
            this.isAllLocation(this.toggleAllShowLocation)
            this.isAllTooltip(this.toggleAllShowTooltip)
        }
//****************
    }


    isActiveTable = (active) => {
        this.setState({
            activeTable: active
        });
    }


// *******Відобразити на карті*******
    toggleAllShowLocation = (active) => {
        this.setState({
            showAllLocation: active
        });
    }
    isAllLocation = (toggleAllShowLocation) => {
        if (this.props.activeMapTrack.length > 0) {
            let isLocation = this.props.activeMapTrack.map(x => x.isLocation).every(elem => elem === true)
            return toggleAllShowLocation(isLocation)
        } else return toggleAllShowLocation(false)
    }
    toggleATLocation = (uid, isLocation) => {
        this.props.toggleATLocation(uid, isLocation)
    }

    allATLocation = (isLocation) => {
        this.props.allATLocation(isLocation)
    }
//****************


//********Відобразити тултип******
    toggleAllShowTooltip = (active) => {
        this.setState({
            showAllTooltip: active
        });
    }
    isAllTooltip = (toggleAllShowTooltip) => {
        if (this.props.activeMapTrack.length > 0) {
            let isTooltip = this.props.activeMapTrack.map(x => x.isTooltip).every(elem => elem === true)

            // props.setTooltipsLocation({coordinates: trackLocation.point.coordinates, uid: tr.uid})
            return toggleAllShowTooltip(isTooltip)
        } else return toggleAllShowTooltip(false)
    }

    toggleATTooltip = (uid, isTooltip) => {
        this.props.toggleATTooltip(uid, isTooltip)
    }
    allATTooltip = (isTooltip) => {
        this.props.allATTooltip(isTooltip)
    }

//********
// зміна групи для трекера

    isSetGroupForTrack = (tr, groupId) => {
        this.props.setGroupForTrack(this.props.mainKey, tr, groupId)
        setTimeout(() => {
            this.props.getTrackData(this.props.mainKey)
        }, 2000);
        setTimeout(() => {
            this.isAllInfo()
        }, 3000);
    }
//*************


// *********** change group function********
    addSelectTrack = (active) => {
        this.setState({
            selectTrack: [...this.state.selectTrack, active]
        });

    }
    delSelectTrack = (active) => {
        this.setState({
            selectTrack: [...this.state.selectTrack.filter(item => item.uid !== active.uid)]
        })
    }
    clearSelectTrack = () => {
        this.setState({
            selectTrack: []
        })
    }

    setGroupTrack = async (groupId) => {
        this.state.selectTrack.forEach(tr => {
            this.props.setGroupForTrack(this.props.mainKey, tr, groupId)
        })
        setTimeout(() => {
            this.props.getTrackData(this.props.mainKey)
        }, 2000);
        setTimeout(() => {
            this.isAllInfo()
        }, 3000);
        this.clearSelectTrack()
    }



    delHiddenCols =  (active) => {
       this.props.delHiddenCols(active)
    }
    toggleHiddenCols =  (active) => {
       this.props.toggleHiddenCols(active)
    }




//****************


    render() {
        if (this.props.status !== "OK") {
            return <Navigate to={"/login"}/>
        } else if (this.state.activeTable === false) {
            return <MonitoringPage {...this.props}
                                   clearSelectTrack={this.clearSelectTrack} addSelectTrack={this.addSelectTrack}
                                   delSelectTrack={this.delSelectTrack} selectTrack={this.state.selectTrack}
                                   setGroupTrack={this.setGroupTrack}
                                   isSetGroupForTrack={this.isSetGroupForTrack}
                                   showAllLocation={this.state.showAllLocation}
                                   showAllTooltip={this.state.showAllTooltip}
                                   toggleAllShowLocation={this.toggleAllShowLocation}
                                   toggleAllShowTooltip={this.toggleAllShowTooltip}
                                   allATLocation={this.allATLocation}
                                   allATTooltip={this.allATTooltip}
                                   toggleATLocation={this.toggleATLocation}
                                   toggleATTooltip={this.toggleATTooltip}
                                   activeTable={this.state.activeTable}
                                   isActiveTable={this.isActiveTable}/>

        } else {
            return <MonitoringTable {...this.props}
                                    showAllLocation={this.state.showAllLocation}
                                    showAllTooltip={this.state.showAllTooltip}
                                    toggleAllShowLocation={this.toggleAllShowLocation}
                                    toggleAllShowTooltip={this.toggleAllShowTooltip}
                                    allATLocation={this.allATLocation}
                                    allATTooltip={this.allATTooltip}
                                    toggleATLocation={this.toggleATLocation}
                                    toggleATTooltip={this.toggleATTooltip}
                                    activeTable={this.state.activeTable}
                                    isActiveTable={this.isActiveTable}
                                    toggleHiddenCols={this.toggleHiddenCols}/>

        }

    }
}

let mapStateToProps = (state) => {
    return {
        status: state.loginReducer.status,
        isStart: state.monitoringReducer.isStart,
        location: state.monitoringReducer.location,
        mainKey: state.loginReducer.key,
        user: state.loginReducer.user,
        delInterval: state.monitoringReducer.delInterval,
        activeMapTrack: state.monitoringReducer.activeMapTrack,
        allInfo: state.monitoringReducer.allInfo,
        hiddenCols: state.paramsReducer.hiddenCols
    }


}

export default connect(mapStateToProps, {
    getTrackData,
    getGroupData,
    toggleStart,
    getLocationData,
    getStreetsData,
    toggleATTooltip,
    toggleATLocation,
    allATTooltip,
    allATLocation,
    setGroupForTrack,
    toggleHiddenCols


})(MonitoringPageContainer);