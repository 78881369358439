import React, {useEffect, useState} from "react";
import '../../Toolbar.css';
import {Form} from 'react-bootstrap';
import locationOn from "../../../../../../assets/icons/location-On.svg"
import locationOff from "../../../../../../assets/icons/toolbar-nav/Location.svg"
import connectionGreen from "../../../../../../assets/icons/Connection-Green.svg"
import connection from "../../../../../../assets/icons/toolbar-nav/Connection-Outline.svg"
import str from "../../../../../../assets/icons/str.svg"
import signal from "../../../../../../assets/icons/Signal-Red.svg"
import tracking from "../../../../../../assets/icons/Tracking.svg"
import tooltipOn from "../../../../../../assets/icons/Tooltip ON.svg"
import tooltipOff from "../../../../../../assets/icons/toolbar-nav/Tooltip.svg"
import car from "../../../../../../assets/icons/Car.svg"
import fastReports from "../../../../../../assets/icons/Fast-report.svg"
import info from "../../../../../../assets/icons/Info.svg"
import StopTimeFunction from "../../../../../HelpFunction/StopTimeFunction";


function AccordionTrack({
                            toggleATTooltip,
                            toggleATLocation,
                            showAllTooltip,
                            activeMapTrack,
                            checkGroup,
                            main,
                            onTrack,
                            offTrack,
                            p,
                            tr,
                            ...props
                        }) {

    let [activeExtraMenu, setActiveExtraMenu] = useState(true);
    const [checkTrack, setTrack] = useState(false);

    let isTooltip = activeMapTrack.find(x => x.uid === tr.uid) ? activeMapTrack.find(x => x.uid === tr.uid).isTooltip : null
    let isLocation = activeMapTrack.find(x => x.uid === tr.uid) ? activeMapTrack.find(x => x.uid === tr.uid).isLocation : null
    let time = new Date(tr.update_timestamp)


    let centerMap = props.location.find(x => x.device_uid === tr.uid) ? props.location.find(x => x.device_uid === tr.uid).point.coordinates : null

    let checkEffect = checkGroup !== undefined ? checkGroup : main

    useEffect(() => {
        setTrack(checkEffect)
        if (checkTrack) {
            offTrack(tr.uid);
        } else if (checkEffect === true) {
            onTrack(tr.uid, true, false)
        }


    }, [checkEffect]);


    return (

        <li>
            {activeExtraMenu && <div className="accordion_body_position">
                <div className="model_car"><img src={car} alt="car"/></div>
                <div className="car_track">
                    <div className="car_info_block">
                        <div className="car_info">
                            <div className="car_checkbox">
                                <Form.Check className="mr-5"

                                            onChange={(e) => {
                                                setTrack(e.target.checked)
                                                if (checkTrack) {
                                                    offTrack(tr.uid);

                                                } else {
                                                    onTrack(tr.uid, true, false)
                                                }
                                            }
                                            }
                                            checked={checkTrack}
                                            type="checkbox"
                                            id={`check${tr.uid}`}/>
                            </div>
                            <div className="car_number">
                                <div>
                                    <div className={tr.stop_time !== "" ? "indicator_stop" : "indicator_move"}></div>
                                    <h6 onClick={() => props.setCenter(centerMap)} title={tr.name}>{tr.name}</h6>
                                </div>
                                <div className="car_speed">
                                    {tr.stop_time === "" ? <p>{`${ tr.connected ? tr.speed : "0"} км/год`}</p> :
                                        <StopTimeFunction time={time} stop_time={tr.stop_time}/>}
                                </div>
                            </div>

                        </div>
                        <div className="car_icon">
                            {isLocation ? <img onClick={() => {

                                toggleATLocation(tr.uid, false)
                            }} src={locationOn} alt="locationOn"/> : <img onClick={() => {

                                toggleATLocation(tr.uid, true)
                            }} src={locationOff} alt="locationOff"/>}
                            {isTooltip ?
                                <img onClick={() => {

                                    toggleATTooltip(tr.uid, false)
                                }} src={tooltipOn} alt="tooltip"/> :
                                <img onClick={() => {

                                    toggleATTooltip(tr.uid, true)
                                }} src={tooltipOff} alt="tooltip"/>}
                            {tr.connected ?
                                <img title="Звязок з сервером" src={connectionGreen} alt="connection"/> :
                                <img title="Звязок з сервером" src={connection} alt="connection"/>}
                            {tr.satellites !== undefined ?
                                <span title="Кількість супутників" className="satellites">{tr.satellites}</span> :
                                <img title="Кількість супутників" src={signal} alt="signal"/>}
                            <img title="Відобразити історію руху" src={tracking} alt="tracking"/>
                            <img src={str} alt="str" className={"str_extra_menu"} onClick={() => {
                                setActiveExtraMenu(!activeExtraMenu)
                            }}/>
                        </div>
                    </div>
                    <div className="car_location">
                        <p>{tr.street}</p>
                    </div>
                    <div className="car_time"><p>{time.toLocaleString()}</p></div>
                </div>
            </div>}

            {!activeExtraMenu && <div className={"extra_menu active_extra_menu"}>
                <ul className={"active_ul_menu"}>
                    <li><img src={tracking} alt="tracking"/> Швидкий трек (сьогодні)</li>
                    <li><img src={fastReports} alt={"fastReports"}/> Швидкий звіт</li>
                    <li><img src={info} alt={"info"}/> Інформація трекера</li>
                </ul>
                <img className={"extra_menu_str"} src={str} alt="str" onClick={() => {
                    setActiveExtraMenu(!activeExtraMenu)
                }}/>

            </div>}
        </li>

    )
}


export default AccordionTrack;
