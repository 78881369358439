import React from "react";
import './Login.css';
import {Row, Col, Button} from 'react-bootstrap';
import logo from "../../assets/login/logo.svg"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {Navigate} from "react-router-dom";




function Login({getAuth,errorMessage,status, ...props}) {
    if(status === "OK"){
        return <Navigate to={"/"}/>
    }

    return (

        <Row className="mx-0 bg" >
            <Col className={"loginForm"} sm={5}>
                <div className="logo_item">
                <img src={logo}/>
                    {console.log("loginReducer.error_message--- " + errorMessage)}
                </div>
                <LoginForm error={errorMessage} getAuth={getAuth}/>
            </Col>
        </Row>
    );
}


function LoginForm({getAuth, ...props}) {
    return (
        <Formik
        initialValues={{ login: '', password: '', remember:false }}
        validate={values => {
            const errors = {};
            if (!values.login) {
                errors.login = 'Поле логін не може бути пусте';
            }else if(values.login.length>30){
                errors.login = 'Максимальна довжина логіна 30 символів';
            }
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {


            setTimeout(() => {
                getAuth(values)
                setSubmitting(false);
            }, 400);
        }}
    >
        {({ isSubmitting }) => (
            <Form className="login">
                <ErrorMessage  name="login" component="div" />
                <Field placeholder="Логін" type="text" name="login" />
                <Field placeholder="Пароль" type="password" name="password" />
                <Field as="select" name="language">
                    <option value="uk">Українська</option>
                    <option value="en">Англійська</option>
                </Field>
                <div className="remember">
                    <Field type="checkbox" name="remember" />
                    <label>
                    Запам’ятати</label>
                </div>
                <Button type="submit" disabled={isSubmitting} variant={"primary"}>Вхід</Button>
            </Form>
        )}
    </Formik>
    )}


export default Login;
