import axios from "axios";


const setMainDozor = (url) => {
    localStorage.test = url
};


export const dozorAPI = {

    authUser(login) {
        return axios.get(`${process.env.REACT_APP_API_M_ENDPOINT}login?u=${login.login}&p=${login.password}`)
            .then(response => {
                if (response.data.status === "OK") {
                    setMainDozor(process.env.REACT_APP_API_M_ENDPOINT)
                    return response
                } else {
                    return axios.get(`${process.env.REACT_APP_API_T_ENDPOINT}login?u=${login.login}&p=${login.password}`)
                        .then(response => {
                            if (response.data.status === "OK") {
                                setMainDozor(process.env.REACT_APP_API_T_ENDPOINT)
                                return response
                            } else {
                                return axios.get(`${process.env.REACT_APP_API_D_ENDPOINT}login?u=${login.login}&p=${login.password}`)
                                    .then(response => {
                                        if (response.data.status === "OK") {
                                            setMainDozor(process.env.REACT_APP_API_D_ENDPOINT)
                                            return response
                                        }
                                        return response
                                    });
                            }
                        });
                }
            });
    },


    getTrack(key) {
        return axios.get(`${localStorage.test}devices/get?key=${key}`)
            .then(response => {
                return response
            });
    },
    getGroup(key) {
        return axios.get(`${localStorage.test}devices/groups/get?key=${key}`)
            .then(response => {
                return response
            });
    },
    getLocation(key) {
        return axios.get(`${localStorage.test}devices/location/get?key=${key}`)
            .then(response => {
                return response
            });
    },
    getStreet(point) {
        return axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lon=${point[0]}&lat=${point[1]}`)
            .then(response => {
                return response
            });
    },

    setGroupForTrack(key, tr, groupId) {
        return axios.post(`${localStorage.test}devices/set?key=${key}`, [{
                "uid": tr.uid,
                "device_group_uid": groupId,
                "catalog_vehicle_uid": tr.catalog_vehicle_uid,
                "catalog_company_uid": tr.catalog_company_uid,
                "catalog_company_department_uid": tr.catalog_company_department_uid,
                "agro_equipment_uid": tr.agro_equipment_uid,
                "name": tr.name,
                "auto_gov_number": tr.auto_gov_number,
                "auto_body_gov_number": tr.auto_body_gov_number,
                "auto_serial_number": tr.auto_serial_number,
                "auto_year": tr.auto_year,
                "auto_color_name": tr.auto_color_name,
                "auto_tech_cond": tr.auto_tech_cond,
                "auto_repair_last_date": tr.auto_repair_last_date,
                "auto_rent": tr.auto_rent,
                "auto_fuel_norm_move": tr.auto_fuel_norm_move,
                "auto_rfid": tr.auto_rfid
            }], {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        ).then(response => {
                return response
            });
    },
    setRenameGroup(key, newName, groupId) {
        return axios.post(`${localStorage.test}devices/groups/set?key=${key}`, [{
            name:newName,
            uid:groupId
            }], {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        ).then(response => {
                return response
            });
    },
    delGroup(key, groupId) {
        return axios.post(`${localStorage.test}devices/groups/del?key=${key}`, [{
            uid:groupId
            }], {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        ).then(response => {
                return response
            });
    }

}