import React, {useEffect, useRef, useState} from "react";
import '../map.css'
import {Marker, Popup, Tooltip} from "react-leaflet";
import {Icon} from "leaflet";
import mrIcon from "../../../../../assets/icons/map-marker.png";


function MapMarker({
                       tooltipCheckbox,
                       tr,
                       toggleATTooltip,
                       map,
                       activeMapTrack,
                       ...props
                   }) {


    let popupRef = useRef();
    const [refReady, setRefReady] = useState(false);
    const [hover, setHover] = useState(false);


    let isPopup = activeMapTrack.find(x => x.uid === tr.uid).isTooltip



    useEffect(() => {
        if (refReady) {
            if (isPopup) {
                map.openPopup(popupRef);
                console.log("+++")

            } else if (isPopup === false) {
                map.closePopup(popupRef);
                console.log("---")
            }
        }


    }, [isPopup, refReady, map]);


    return <Marker riseOnHover zIndexOffset={hover ? 20 : 1}
                   eventHandlers={{
                       mouseover: () => {
                           setHover(true)
                       },
                       mouseout: () => {
                           setHover(false)
                       },
                       popupopen: () => {
                           !isPopup && toggleATTooltip(tr.uid, true)
                           !isPopup && console.log("d")

                       },
                       popupclose: () => {
                           isPopup && toggleATTooltip(tr.uid, false)
                           isPopup && console.log("dd")
                       }
                   }}


                   position={[tr.point.coordinates[1], tr.point.coordinates[0]]}
                   icon={tr.connected ? new Icon({iconUrl: mrIcon, iconSize: [41, 41]}) : new Icon({
                       iconUrl: mrIcon,
                       iconSize: [41, 41],
                       className: "blur"
                   })}>


        <Popup autoPan={false} offset={[0, -10]} ref={(r) => {
            popupRef = r;
            setRefReady(true);
        }}
               autoClose={false} closeOnClick={false}
        >
            <h1 onClick={() => console.log(props.tooltipsLocation)} title={tr.name}>{tr.name}</h1>
            {tooltipCheckbox.trackTime && <div><p className="marker_title">Час трекера</p> <p
                className="marker_info">{new Date(tr.tracker_timestamp).toLocaleString()}</p>
            </div>}
            {tooltipCheckbox.serverTime && <div><p className="marker_title">Час сервера</p> <p
                className="marker_info">{new Date(tr.server_timestamp).toLocaleString()}</p>
            </div>}
            {tooltipCheckbox.satellite &&
            <div><p className="marker_title">Супутники</p> <p className="marker_info">{tr.satellites}</p>
            </div>}
            {tooltipCheckbox.speed &&
            <div><p className="marker_title">Швидкість</p> <p className="marker_info">{`${ tr.connected ? tr.speed : "0"} км/год`} </p>
            </div>}
            {tooltipCheckbox.sensorStatus && <div><p className="marker_title">Двигун</p> <p
                className="marker_info">{tr.stop_time === "" ? "ON" : "OFF"}</p></div>}
            {tooltipCheckbox.address && <p className="marker_street">{tr.street}</p>}

        </Popup>
        <Tooltip interactive
                 className={tr.connected ? (tr.stop_time === "" ? "tooltip_move tooltip_name" : "tooltip_stop tooltip_name") : "tooltip_no_connected tooltip_name"}
                 direction="bottom" offset={[0, 20]} permanent>
            {tr.name}
        </Tooltip>
    </Marker>

}

export default MapMarker;
