import React from "react";
import {Navigate} from "react-router-dom";


function ReportsPage(props) {
    if(props.status !== "OK"){
        return <Navigate to={"/login"}/>
    }
    return (
        <div>
            <h1>ReportsPage</h1>
        </div>
    );
}

export default ReportsPage;
