import React from "react";
import {connect} from "react-redux";
import MapBlock from "./MapBlock";
import {setCenter, toggleATTooltip} from "../../../../Redux/monitoringReducer";
import {setTooltipCheckbox} from "../../../../Redux/paramsReducer";


class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipsLocation: []
        };
    }

    componentDidUpdate(prevProps, prevState) {
// *******Відобразити на карті та тултип*******
        if (prevProps.activeMapTrack !== this.props.activeMapTrack) {
            this.setTooltipsLocation()
        }
        if (prevProps.location !== this.props.location) {
            this.setTooltipsLocations()
        }
//****************
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state || nextProps !== this.props
    }

    isTooltipCheckbox = (active) => {
        this.props.setTooltipCheckbox(active)

    }


    // bounds when tooltip open
    setTooltipsLocation = () => {
        setTimeout(() => {
            this.props.activeMapTrack.forEach(tr => {
                if (tr.isTooltip) {
                    let trackLocation = this.props.allInfo.find(x => x.uid === tr.uid)

                    if (!this.state.tooltipsLocation.map(x => x.uid).includes(tr.uid)) {
                        this.setState({
                            tooltipsLocation: [...this.state.tooltipsLocation, {
                                coordinates: trackLocation.point.coordinates,
                                uid: tr.uid
                            }]
                        });
                    }
                } else if (!tr.isTooltip) {

                    this.setState({
                        tooltipsLocation: [...this.state.tooltipsLocation.filter(item => item.uid !== tr.uid)]

                    });
                }

            })
        }, 500);
    }

    setTooltipsLocations = () => {
        this.props.activeMapTrack.forEach(tr => {
            if (tr.isTooltip) {
                let trackLocation = this.props.location.find(x => x.device_uid === tr.uid)
                this.setState({
                    tooltipsLocation: this.state.tooltipsLocation.map(item => item.uid === tr.uid ? {
                        coordinates: trackLocation.point.coordinates,
                        uid: item.uid
                    } : item)
                });
            }})


    }


    setBoundsTooltip = () => {
        let min = [9999, 9999]
        let max = [-9999, -9999]

        this.state.tooltipsLocation.forEach(el => {
            if (el.coordinates[0] > max[1]) {
                max[1] = el.coordinates[0]+0.2
            }
            if (el.coordinates[1] > max[0]) {
                max[0] = el.coordinates[1]+0.7
            }
            if (el.coordinates[1] < min[0]) {
                min[0] = el.coordinates[1]-0.2
            }

            if (el.coordinates[0] < min[1]) {
                min[1] = el.coordinates[0]-0.2
            }
        })
        return [min, max]
    }

    ///******************


    setCenter = (activeTrack) => {
        this.props.setCenter(activeTrack)
    }

    render() {
        return <MapBlock {...this.props}
                         isTooltipCheckbox={this.isTooltipCheckbox} setCenter={this.setCenter}
                         tooltipsLocation={this.state.tooltipsLocation} setBoundsTooltip={this.setBoundsTooltip}/>
    }
}

let mapStateToProps = (state) => {
        return {
            activeMapTrack: state.monitoringReducer.activeMapTrack,
            tooltipCheckbox: state.paramsReducer.tooltipCheckbox,
            center: state.monitoringReducer.center,
            allInfo: state.monitoringReducer.allInfo,

        }


    }

export default connect(mapStateToProps, {toggleATTooltip, setCenter, setTooltipCheckbox})

(
    MapContainer
)
;