import React, {useEffect, useState} from "react";
import '../Toolbar.css';
import {Col, Form, Accordion, Card} from 'react-bootstrap';
import AccordionTrack from "./AccordionTrack/AccordionTrack";
import CustomToggle from "../ToggleAccordion/ToggleAccordion";


function AccordionGroup({open, showTrack, main, setActiveAll, activeAll, p, ...props}) {


    const [checkGroup, setGroup] = useState(false);

    useEffect(() => {
        setGroup(main)
    }, [main]);


    let track = showTrack.map(tr => {
        if (p.uid === tr.device_group_uid) {
            return <AccordionTrack key={tr.uid} checkGroup={checkGroup} streets={props.streets}
                                   activeMapTrack={props.activeMapTrack}
                                   location={props.location} onTrack={props.onTrack}
                                   setCenter={props.setCenter}
                                   offTrack={props.offTrack} p={p} tr={tr}
                                   showAllLocation={props.showAllLocation}
                                   showAllTooltip={props.showAllTooltip}
                                   toggleATLocation={props.toggleATLocation}
                                   toggleATTooltip={props.toggleATTooltip}

            />
        } else return null
    })

    return (
        <Accordion>
            <Card>
                <Card.Header style={{"display": "flex"}}><Col lg="1" className={"accordion_checkbox"}><Form.Check
                    bg="background: #5383FF;"
                    checked={checkGroup}
                    name={`checkbox-${p.uid}`}
                    onChange={(e) => {
                        setGroup(e.target.checked)
                    }}
                    type="checkbox"
                    id={`checkbox-${p.uid}`}/></Col>
                    <CustomToggle open={open} eventKey={p.uid}>{p.name}</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={p.uid}>
                    <Card.Body>
                        <ul className={"toolbar_track_list"}>
                            {track}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default AccordionGroup;
