import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk"
import monitoringReducer from "./monitoringReducer";
import loginReducer from "./loginReducer";
import paramsReducer from "./paramsReducer";



let rootReducer = combineReducers({
    monitoringReducer: monitoringReducer,
    loginReducer: loginReducer,
    paramsReducer: paramsReducer
})




const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["loginReducer", "paramsReducer"]

}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunkMiddleware)
));


window.__store__ = store

export const persistor =  persistStore(store)
export default store





