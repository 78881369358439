import React from "react";
import {connect} from "react-redux";
import Header from "./Header";
import {
    logoutDelData,
} from "../../Redux/monitoringReducer";
import {logout} from "../../Redux/loginReducer";
import {logoutParams} from "../../Redux/paramsReducer";




class HeaderContainer extends React.Component {

    logout = () => {
        this.props.logout()
        this.props.logoutDelData()
        this.props.logoutParams()
    }

    render() {
        if(this.props.status !== "OK"){
            return null
        }else
        return <Header {...this.props} logout={this.logout} />
    }
}

let mapStateToProps = (state) => {
    return {
        status: state.loginReducer.status,
    }


}

export default connect(mapStateToProps, {logout,logoutDelData, logoutParams})(HeaderContainer);