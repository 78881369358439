import React, {useEffect, useState} from "react";
import '../../Toolbar.css';
import {Col, Accordion, Card} from 'react-bootstrap';
import CustomToggle from "../../ToggleAccordion/ToggleAccordion";
import MenuAccordionTrack from "./MenuAccordionTrack/MenuAccordionTrack";
import btnDeleteGroup from "../../../../../../assets/icons/delete.svg";
import btnChangeGroup from "../../../../../../assets/icons/tools-menu/Pen.svg";



function MenuAccordion({p, deleteGroup, toggleGroupMenu, toggleEditGroup,toggleGroupUid, ...props}) {


    function groupLength() {
        let stack = 0
        props.track.forEach(el => {
            if (el.device_group_uid === p.uid)
                stack++
        })
        return stack
    }


    let track = props.track.map(tr => {
        if (p.uid === tr.device_group_uid) {

            return <MenuAccordionTrack isSetGroupForTrack={props.isSetGroupForTrack} key={tr.uid} p={p} tr={tr}
                                       isDeleteBtn={true}/>
        } else return null
    })

    return (<>
            <Accordion>
                <Card>
                    <Card.Header style={{"display": "flex"}}>
                        <CustomToggle accordion={"groupMenu"} eventKey={p.uid}>
                            <div className={"menu_group_name"}>
                                <h5>{p.name}</h5>
                                <h6>{groupLength()} трекерів</h6>
                            </div>
                        </CustomToggle>
                        <Col lg="3" className={"menu_title_btn"}>
                            <img src={btnChangeGroup} alt={"btnChangeGroup"} onClick={() => {
                                toggleEditGroup(true)
                                toggleGroupUid(p.uid)
                                    toggleGroupMenu(false)
                            }}/>
                            <img src={btnDeleteGroup} alt={"btnDeleteGroup"} onClick={()=>{deleteGroup(p.uid)}}/>
                        </Col>
                    </Card.Header>
                    <Accordion.Collapse eventKey={p.uid}>
                        <Card.Body>
                            <ul className={"menu_track_list"}>
                                {track}
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </>
    );
}

export default MenuAccordion;
