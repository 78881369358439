export default function StopTimeFunction({time, stop_time, ...props}) {
    let stopTime = Math.ceil((time - new Date(stop_time)) / 1000 / 60)
    let stopTimeHours = 0
    let stopTimeDay = 0
    if (stopTime > 60) {
        stopTimeHours = (Math.ceil(stopTime / 60)-1)
        stopTime = Math.ceil(stopTime % 60)
    }
    if (stopTimeHours > 24) {
        stopTimeDay = (Math.ceil(stopTimeHours / 24)-1)
        stopTimeHours = stopTimeHours % 24
    }
    if (stopTimeDay > 0) {
        return <p>{`${stopTimeDay}  дн. ` + `${stopTimeHours}  год. ` + `${stopTime}   хв.`}</p>
    } else if (stopTimeDay === 0 && stopTimeHours > 0) {
        return <p>{`${stopTimeHours} год. ` + `${stopTime} хв.`}</p>
    } else if (stopTimeDay === 0 && stopTimeHours === 0 && stopTime > 0) {
        return <p>{`${stopTime} хв.`}</p>
    }
}