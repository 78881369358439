import React from "react";
import {Navigate} from "react-router-dom";


function TrackPage(props) {

    return (
        <div>
            <h1>TrackPage</h1>
        </div>
    );
}

export default TrackPage;
