import React, {useEffect, useState} from "react";
import '../MonitoringPage/MonitoringMap/Toolbar/Toolbar.css';
import {Button, InputGroup, FormControl} from 'react-bootstrap';
import search from "../../assets/icons/search_icon.svg"
import clSearch from "../../assets/icons/X.svg"


function SearchBlock({showTrack, toggleShowTrack, fullList, placeholder, inputStyle, ...props}) {


    const [foundTrack, setFoundTrack] = useState([]); //пошук по назві збіги
    const [searchValue, setSearchValue] = useState(""); //пошук по назві значення інпута
    const [openSearch, setOpenSearch] = useState(false); //пошук по назві вкл-викл пошук

    toggleShowTrack(openSearch ? foundTrack : fullList)


//*******search*************
    let handleSearch = (e) => {
        setSearchValue(e)
        let findTrack = showTrack.filter(function (item) {
            return item.name.toLowerCase().search(
                e.toLowerCase()) !== -1;
        });
        setFoundTrack(findTrack)
        setOpenSearch(true)
    }
    let closeSearch = () => {
        setSearchValue("")
        setFoundTrack([])
    }
    useEffect(() => {
        searchValue === "" && setOpenSearch(false)
    }, [searchValue]);


//********************

    if (inputStyle === "table") {
        return (
            <>
                <input className="table_input"
                             type="text"
                             value={searchValue}
                             onChange={(e) => handleSearch(e.target.value)}/>
            </>
        );
    }else {
        return ( <InputGroup>
            <FormControl
                placeholder={placeholder}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}

            />
            {openSearch ?
                <Button className={"input_btn_search"} onClick={() => closeSearch()}>
                    <img src={clSearch} alt="closeSearch"/>
                </Button> :
                <Button className={"input_btn_search"}>
                    <img src={search} alt="search"/>
                </Button>
            }
        </InputGroup>)
    }
}

export default SearchBlock;
