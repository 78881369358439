import React, {useState} from "react";
import '../Toolbar.css';
import {
    Col,
    Row,
    Modal,
    Button,
    Container
} from 'react-bootstrap';
import createGroup from "../../../../../assets/icons/Add-Group.svg";
import AddToGroup from "../../../../../assets/icons/AddToGroup.svg";
import MenuAccordion from "./MenuAccordion/MenuAccordion";
import AddGroupMenu from "./AddGroupMenu/AddGroupMenu";
import EditGroupMenu from "./MenuAccordion/EditGroupMenu/EditGroupMenu";
import AddTrackersMenu from "./MenuAccordion/AddTrackersMenu/AddTrackersMenu";
import SearchBlock from "../../../../HelpFunction/SearchBlock";


function ChangeGroupMenu({toggleGroupMenu, groupMenu, ...props}) {

    const [isCreateGroupMenu, toggleCreateGroupMenu] = useState(false); //меню cтворити групу
    const [addTrackers, toggleAddTrackers] = useState(false); //відкрити меню редагування групи
    const [editGroup, toggleEditGroup] = useState(false); //відкрити меню редагування групи
    const [groupUid, toggleGroupUid] = useState(null); //uid групи для редагування групи

    const [showTrack, toggleShowTrack] = useState([...props.group]);



    let group = showTrack.map(p => <MenuAccordion key={p.uid}
                                                    deleteGroup={props.deleteGroup}
                                                    toggleEditGroup={toggleEditGroup}
                                                    toggleGroupUid={toggleGroupUid}
                                                    toggleGroupMenu={toggleGroupMenu}
                                                    p={p}
                                                    track={props.track}
                                                    isSetGroupForTrack={props.isSetGroupForTrack}/>)

    return (<>
        <Modal dialogClassName={"change_group_menu"} centered show={groupMenu} onHide={() => toggleGroupMenu(false)}>
            <Modal.Header closeButton>
                <Container>
                    <Modal.Title>Групи об’єктів</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container className={"group_menu_body"}>
                    <Row>
                        <Col xs={12}>
                            <Button className={"btn_create_group"} variant="primary"
                                    onClick={() => {
                                        toggleGroupUid(Math.random().toString(36).slice(2))
                                        toggleCreateGroupMenu(true)
                                        toggleGroupMenu(false)
                                    }}>
                                <span>Створити групу</span>
                                <img src={createGroup} alt={"createGroup"}/>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                           <SearchBlock showTrack={showTrack} toggleShowTrack={toggleShowTrack}
                                        fullList={props.group} placeholder={"Пошук групи"}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={"group_space"}>
                            {group}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={"position_add_to_group"}>
                            <Button className={"btn_add_to_group"} variant="primary"
                                    onClick={() => toggleGroupMenu(false)}>
                                <span>Додати до групи</span>
                                <img src={AddToGroup} alt={"AddToGroup"}/>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

        </Modal>

        <AddGroupMenu renameGroup={props.renameGroup} groupUid={groupUid} toggleGroupUid={toggleGroupUid} toggleAddTrackers={toggleAddTrackers}  toggleCreateGroupMenu={toggleCreateGroupMenu} isCreateGroupMenu={isCreateGroupMenu} toggleGroupMenu={toggleGroupMenu}/>
        <EditGroupMenu renameGroup={props.renameGroup} track={props.track}   toggleAddTrackers={toggleAddTrackers} groupUid={groupUid} toggleEditGroup={toggleEditGroup}  editGroup={editGroup} toggleGroupMenu={toggleGroupMenu} />
        <AddTrackersMenu addSelectTrack={props.addSelectTrack} delSelectTrack={props.delSelectTrack}
                         selectTrack={props.selectTrack} clearSelectTrack={props.clearSelectTrack}
                         track={props.track} groupUid={groupUid} addTrackers={addTrackers} toggleAddTrackers={toggleAddTrackers}
                         setGroupTrack={props.setGroupTrack}/>
    </>);
}


export default ChangeGroupMenu

