import React from "react";
import './MonitoringMap.css';
import {Row, Col} from 'react-bootstrap';
import Preloader from "../../Preloader/Preloader";
import ToolbarContainer from "./Toolbar/ToolbarContainer";
import MapContainer from "./Map/MapContainer";


function MonitoringMap(props) {


    if (props.isStart !== true) {
        return <Preloader/>
    }
    return (

        <Row className="mx-0">
            <Col className="px-0" sm={3}><ToolbarContainer clearSelectTrack={props.clearSelectTrack}
                                                           addSelectTrack={props.addSelectTrack}
                                                           delSelectTrack={props.delSelectTrack}
                                                           selectTrack={props.selectTrack}
                                                           setGroupTrack={props.setGroupTrack}
                                                           isSetGroupForTrack={props.isSetGroupForTrack}
                                                           // allInfoAboutTrack={props.allInfoAboutTrack}
                                                           showAllLocation={props.showAllLocation}
                                                           showAllTooltip={props.showAllTooltip}
                                                           toggleAllShowLocation={props.toggleAllShowLocation}
                                                           toggleAllShowTooltip={props.toggleAllShowTooltip}
                                                           allATLocation={props.allATLocation}
                                                           allATTooltip={props.allATTooltip}
                                                           toggleATLocation={props.toggleATLocation}
                                                           toggleATTooltip={props.toggleATTooltip}/></Col>


            <Col className="px-0" sm={9}><MapContainer activeTable={props.activeTable}
                                                       isActiveTable={props.isActiveTable}
                                                       tooltipsLocation={props.tooltipsLocation} setBoundsTooltip={props.setBoundsTooltip}
                                                       setTooltipsLocation={props.setTooltipsLocation} delTooltipsLocation={props.delTooltipsLocation}
            /></Col>
        </Row>
    );
}

export default MonitoringMap;
