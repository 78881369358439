import React from "react";
import {connect} from "react-redux";
import Toolbar from "./Toolbar";
import {
    delActiveTrack, delGroup, getTrackData,
    setActiveTrack,
    setCenter, setRenameGroup
} from "../../../../Redux/monitoringReducer";


class ToolbarContainer extends React.Component {


    onTrack = (activeTrack, isLocation, isTooltip) => {
        this.props.setActiveTrack(activeTrack, isLocation, isTooltip)
    }
    setCenter = (activeTrack) => {
        this.props.setCenter(activeTrack)
    }
    offTrack =  (activeTrack) => {
         this.props.delActiveTrack(activeTrack)
    }


    renameGroup =  (newName, groupId) => {
        this.props.setRenameGroup(this.props.mainKey, newName, groupId)
    }

    deleteGroup =  (groupId) => {
        this.props.delGroup(this.props.mainKey, groupId)
    }


    render() {

        return <Toolbar {...this.props}
                        renameGroup={this.renameGroup}
                        setCenter={this.setCenter}
                        onTrack={this.onTrack}
                        offTrack={this.offTrack}
                        deleteGroup={this.deleteGroup}/>

    }
}

let mapStateToProps = (state) => {
    return {
        mainKey: state.loginReducer.key,
        track: state.monitoringReducer.track,
        group: state.monitoringReducer.group,
        location: state.monitoringReducer.location,
        isStart: state.monitoringReducer.isStart,
        streets: state.monitoringReducer.streets,
        activeMapTrack: state.monitoringReducer.activeMapTrack,
        allInfo: state.monitoringReducer.allInfo

    }


}

export default connect(mapStateToProps, {
    setCenter,
    setActiveTrack, delActiveTrack,getTrackData,setRenameGroup,delGroup
})(ToolbarContainer);