import {Row, Col, Table} from 'react-bootstrap';
import location from "../../../assets/icons/toolbar-nav/Location.svg"
import locationOn from "../../../assets/icons/location-On.svg"
import connection from "../../../assets/icons/toolbar-nav/Connection-Outline.svg"
import signal from "../../../assets/icons/toolbar-nav/Satellite-Signal.svg"
import tooltip from "../../../assets/icons/toolbar-nav/Tooltip.svg"
import tooltipOn from "../../../assets/icons/Tooltip ON.svg"
import arrow from "../../../assets/icons/Arrow-Left.svg"
import doc from "../../../assets/icons/Doc.svg"
import gear from "../../../assets/icons/Gear.svg"
import pdf from "../../../assets/icons/Pdf.svg"
import xls from "../../../assets/icons/Xls.svg"
import sort from "../../../assets/icons/Sort-Tables.svg"
import "./table.css"
import React, {useEffect, useState} from "react";
import MainMenu from "./MainMenu/MainMenu";
import Preloader from "../../Preloader/Preloader";
import TableBody from "./TableBody/TableBody";



function MonitoringTable({
                             showAllLocation,
                             showAllTooltip,
                             toggleAllShowLocation,
                             toggleAllShowTooltip,
                             track,
                             allInfo,
                             hiddenCols,
                             ...props
                         }) {




    const [sortBy, toggleSort] = useState("name");
    const [sortByName, toggleSortByName] = useState(true);
    const [sortByStreet, toggleSortByStreet] = useState(false);


    const [foundTrack, setFoundTrack] = useState([]); //пошук по назві збіги
    const [searchValue, setSearchValue] = useState(""); //пошук по назві значення інпута
    const [openSearch, setOpenSearch] = useState(false); //пошук по назві вкл-викл пошук

    // ************сортування*************
    let sortTracker = [...allInfo]

    function SortArray(x, y) {

        if (sortBy === "name") {
            if (sortByName) {
                return x.name.localeCompare(y.name);
            } else return y.name.localeCompare(x.name);
        }
    }

    sortTracker.sort(SortArray);

    let showTrack = openSearch ? foundTrack : sortTracker

    
    //*******search*************
    let handleSearch = (e) => {
        setSearchValue(e)
        let findTrack = allInfo.filter(function (item) {
            return item.name.toLowerCase().search(
                e.toLowerCase()) !== -1;
        });
        setFoundTrack(findTrack)
        setOpenSearch(true)
    }
    useEffect(() => {
        searchValue === "" && setOpenSearch(false)
    }, [searchValue]);


//********************


    let tableBody = showTrack.map((tr) => <TableBody key={tr.uid} tr={tr}
                                                     location={props.location}
                                                     hiddenCols={hiddenCols}
                                                     activeMapTrack={props.activeMapTrack}
                                                     toggleATLocation={props.toggleATLocation}
                                                     toggleATTooltip={props.toggleATTooltip}/>)
    const [activeMenu, toggleActiveMenu] = useState(false);


    if (props.isStart !== true) {
        return <Preloader/>
    }
    return (
        <div className="table_margin">
            <Row className="mx-0 ">
                <Col className="px-0 mx-auto table_menu" sm={12}>
                    <div className="close_table">
                        <span style={{"cursor": "pointer"}} onClick={() => {
                            props.isActiveTable(!props.activeTable)
                        }}><img src={arrow} alt="btnBack"/></span>

                        <h2>Назва таблиці</h2>
                    </div>
                    <div className="table_item">
                        <img src={pdf} alt="pdf"/>
                        <img src={xls} alt="xls"/>
                        <img src={doc} alt="doc"/>
                        <img className={activeMenu ? "gear" : ""} src={gear} alt="gear"
                             onClick={() => {
                                 toggleActiveMenu(!activeMenu)
                             }}/>
                    </div>
                    <MainMenu activeMenu={activeMenu}  toggleHiddenCols={props.toggleHiddenCols} hiddenCols={hiddenCols}/>
                </Col>

            </Row>
            <Row className="mx-0">
                <Col className="px-0 mx-auto " sm={12}>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>
                                <div className="th_align title_name">
                                    <div>НАЗВА ТРЕКЕРА<img onClick={() => {
                                        toggleSort("name")
                                        toggleSortByName(!sortByName)
                                    }} src={sort} alt={sort}/></div>
                                    <input className="table_input"
                                           type="text"
                                           value={searchValue}
                                           onChange={(e) => handleSearch(e.target.value)}/>
                                </div>
                            </th>
                            <th>
                                <div className="th_align title_address">
                                    <div>АДРЕССА <img src={sort} alt={sort}/></div>
                                </div>
                            </th>
                            <th>
                                <div className="th_align"> {showAllTooltip ?
                                    <img title="Cховати тултип" onClick={() => {
                                        toggleAllShowTooltip(false)
                                        props.allATTooltip(false)
                                    }} src={tooltipOn} alt="tooltip"/> :
                                    <img title="Відобразити тултип" onClick={() => {
                                        toggleAllShowTooltip(true)
                                        props.allATTooltip(true)
                                    }} src={tooltip}
                                         alt="tooltip"/>}</div>
                            </th>
                            <th>
                                <div  className="th_align">{showAllLocation ?
                                    <img title="Cховати на карті" onClick={() => {
                                        toggleAllShowLocation(false)
                                        props.allATLocation(false)
                                    }} src={locationOn}
                                         alt="locationOn"/> :
                                    <img title="Відобразити на карті" onClick={() => {
                                        toggleAllShowLocation(true)
                                        props.allATLocation(true)
                                    }} src={location}
                                         alt="locationOff"/>}</div>
                            </th>
                            <th>
                                <div className="th_align"><img src={connection} alt={connection}/></div>
                            </th>
                            <th>
                                <div className="th_align"><img src={signal} alt={signal}/></div>
                            </th>
                            {!hiddenCols.map(x => x).includes("enterprise") && <th>
                                <div className="th_align title_company">
                                    <div>ПІДПРИЄМСТВО <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("subdivision") &&  <th>
                                <div className="th_align  title_subdivision">
                                    <div>ПІДРОЗДІЛ <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("driver") &&  <th>
                                <div className="th_align title_driver">
                                    <div>ВОДІЙ <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("drp") &&  <th>
                                <div className="th_align title_drp">
                                    <div>ДРП <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("transportGroup") &&  <th>
                                <div className="th_align title_transport_group">
                                    <div>ГРУПА ТРАНСПОРТНИХ ЗАСОБІВ <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("model") &&  <th>
                                <div className="th_align title_model">
                                    <div>МАРКА МОДЕЛЬ <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/></div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("trackerTime") &&  <th>
                                <div className="th_align title_track_time">
                                    <div>ЧАС ТРЕКЕРА <img src={sort} alt={sort}/></div>
                                </div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("hired") &&    <th>
                                <div className="th_align title_hired">
                                    <div>НАЙМАНИЙ <img src={sort} alt={sort}/></div>
                                    <input className="table_input" type="text" name="name"/>
                                </div>
                            </th>}
                            {!hiddenCols.map(x => x).includes("speedStopTime") &&     <th>
                                <div className="th_align title_speed">
                                    <div>ШВИДКІСТЬ ЧАС ЗУПИНКИ <img src={sort} alt={sort}/></div>
                                </div>
                            </th>}
                        </tr>
                        </thead>
                        <tbody>
                        {tableBody}
                        </tbody>
                    </Table>

                </Col>
            </Row>
        </div>
    );
}

export default MonitoringTable;