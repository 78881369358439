import React from "react";
import '../map.css'
import {TileLayer, LayersControl, WMSTileLayer} from "react-leaflet";


function LayersControlMap(props) {
        return  <LayersControl position="topleft">
            <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="BKG web_scale_grau">
                <TileLayer
                    url="http://sgx.geodatenzentrum.de/wmts_topplus_web_open/tile/1.0.0/web_scale_grau/default/WEBMERCATOR/{z}/{y}/{x}.png"
                    attribution='&copy; <a href=\"http://www.bkg.bund.de\" target=\"_new\">Bundesamt f&uuml;r Kartographie und Geod&auml;sie</a> 2017, <a href="https://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf" target=\"_new\">Datenquellen</a>'
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="ESRI Sattelite ">
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="BKG">
                <TileLayer
                    url="http://sgx.geodatenzentrum.de/wmts_topplus_web_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
                    attribution='&copy; <a href=\"http://www.bkg.bund.de\" target=\"_new\">Bundesamt f&uuml;r Kartographie und Geod&auml;sie</a> 2017, <a href="https://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf" target=\"_new\">Datenquellen</a>'
                />
            </LayersControl.BaseLayer>
        </LayersControl>


}

export default LayersControlMap;
