 import React, {useEffect, useState} from "react";
import '../Toolbar.css';
 import {Col, useAccordionButton} from "react-bootstrap";
 import str from "../../../../../assets/icons/str.svg"


 function CustomToggle({accordion, open, children, eventKey }) {
     const [main, setMain] = useState(false);
     const decoratedOnClick = useAccordionButton(eventKey, () =>
         setMain(!main),
     );

     useEffect(() => {
         ((open === true && !main) || (open === false && main)) && decoratedOnClick()
     }, [open]);

     if(accordion === "groupMenu") {
         return (
             <Col lg="9" className={"menu_accordion_title"} onClick={() => decoratedOnClick()}
             >
                 <img className={main ? "accordion_title_image_open" : "accordion_title_image"} alt={"str"} src={str}/>
                 {children}

             </Col>
         );
     }else return (

         <Col lg="11" className={"accordion_title"} onClick={() => decoratedOnClick()}
              style={{"width": "90%"}}
         >
             {children}
             <img className={main ? "accordion_title_image_open" : "accordion_title_image"} alt={"str"} src={str}/>
         </Col>
     );
 }


export default CustomToggle;
