import React from "react";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import TrackPage from "./TrackPage";


class TrackPageContainer extends React.Component {

    render() {
        if(this.props.status !== "OK"){
            return <Navigate to={"/login"}/>
        }
        return <TrackPage {...this.props} />
    }
}


let mapStateToProps = (state) => {
    return {
        status: state.loginReducer.status,
    }
}

export default connect(mapStateToProps, {})(TrackPageContainer);