import React, {useEffect, useState} from "react";
import '../../map.css';
import {
    Col,
    Row,
    Form,
    Modal,
    Button,
    Container
} from 'react-bootstrap';


function TooltipMenu({tooltipCheckbox, isTooltipCheckbox, activeTooltipMenu, toggleTooltipMenu, ...props}) {

    const [cancel, setCancel] = useState(false)
    const [tooltipMenuTooltipCheckbox, setTooltipMenuTooltipCheckbox] = useState({
        trackTime: true,
        serverTime: true,
        satellite: true,
        speed: true,
        sensorStatus: true,
        address: true,
    });

    useEffect(() => {
        setTooltipMenuTooltipCheckbox(tooltipCheckbox)
    }, [cancel]);


    return (
        <Modal dialogClassName={"tooltip_menu"} centered show={activeTooltipMenu}
               onHide={() => toggleTooltipMenu(false)}>
            <Modal.Header className={"tooltip_menu"}>
                <Container>
                    <Modal.Title>Тултип трекера</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label="Час трекера"
                                name="trackTime"
                                type="checkbox"
                                checked={tooltipMenuTooltipCheckbox.trackTime}
                                id={"trackTime"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        trackTime: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label={"Час сервера"}
                                name={"serverTime"}
                                checked={tooltipMenuTooltipCheckbox.serverTime}
                                type="checkbox"
                                id={"serverTime"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        serverTime: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label={"Супутники"}
                                name={"satellite"}
                                checked={tooltipMenuTooltipCheckbox.satellite}
                                type="checkbox"
                                id={"satellite"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        satellite: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label={"Швидкість"}
                                name={"speed"}
                                checked={tooltipMenuTooltipCheckbox.speed}
                                type="checkbox"
                                id={"speed"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        speed: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label={"Стан датчиків"}
                                name={"sensorStatus"}
                                checked={tooltipMenuTooltipCheckbox.sensorStatus}
                                type="checkbox"
                                id={"sensorStatus"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        sensorStatus: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                label={"Адреса"}
                                name={"address"}
                                checked={tooltipMenuTooltipCheckbox.address}
                                type="checkbox"
                                id={"address"}
                                onChange={(e) => {
                                    setTooltipMenuTooltipCheckbox({
                                        ...tooltipMenuTooltipCheckbox,
                                        address: e.target.checked
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"btn_tooltip_position"} xs={12}>
                            <Button size={"sm"} className={"btn_tooltip_menu left"} variant="primary" onClick={() => {
                                setCancel(!cancel)
                                toggleTooltipMenu(false)
                            }}>
                                Скасувати
                            </Button>
                            <Button size={"sm"} onClick={() => {
                                isTooltipCheckbox(tooltipMenuTooltipCheckbox)
                                toggleTooltipMenu(false)
                            }} className={"btn_tooltip_menu"} variant="primary">
                                Зберегти
                            </Button></Col>
                    </Row>

                </Container>
            </Modal.Body>
        </Modal>
    );
}


export default TooltipMenu

