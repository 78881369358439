import React from "react";
import '../table.css'
import pen from "../../../../assets/icons/tools-menu/Pen.svg";
import clock from "../../../../assets/icons/toolbar-nav/Clock.svg";
import speedometer from "../../../../assets/icons/toolbar-nav/Speedometer.svg";


function MainMenu({toggleHiddenCols, hiddenCols, ...props}) {
    return <div className={!props.activeMenu ? "hidden_main_menu main_menu " : "main_menu active_main_menu"}>
        <ul className={!props.activeMenu ? "hidden_ul_menu" : "active_ul_menu"}>
            <li className={!hiddenCols.map(x => x).includes("enterprise") ? "active_li" : undefined} onClick={() =>{
                toggleHiddenCols("enterprise")
            }}>
                <img src={pen} alt={pen}/>Підприємство
            </li>
            <li className={!hiddenCols.map(x => x).includes("subdivision")  ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("subdivision")
            }}><img src={pen} alt={pen}/>Підрозділ
            </li>
            <li className={!hiddenCols.map(x => x).includes("driver") ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("driver")
            }}><img src={pen} alt={pen}/>Водій
            </li>
            <li className={!hiddenCols.map(x => x).includes("drp") ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("drp")
            }}><img src={pen} alt={pen}/>ДРП
            </li>
            <li className={!hiddenCols.map(x => x).includes("transportGroup") ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("transportGroup")
            }}><img src={pen} alt={pen}/>Група траснпортних засобів
            </li>
            <li className={!hiddenCols.map(x => x).includes("model")? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("model")
            }}><img src={pen} alt={pen}/>Марка, модель
            </li>
            <li className={!hiddenCols.map(x => x).includes("trackerTime") ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("trackerTime")
            }}><img src={clock} alt={clock}/>Час трекера
            </li>
            <li className={!hiddenCols.map(x => x).includes("hired") ? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("hired")
            }}><img src={pen} alt={pen}/>Найманий
            </li>
            <li className={!hiddenCols.map(x => x).includes("speedStopTime")? "active_li" : undefined} onClick={() => {
                toggleHiddenCols("speedStopTime")
            }}><img src={speedometer} alt={speedometer}/>Швидкість/час зупинки
            </li>
        </ul>
    </div>


}

export default MainMenu;
