import React, {useState} from "react";
import './Header.css';
import {
    Navbar,
    Nav,
    Col,
    Row,
    Form,
    ButtonGroup,
    ToggleButton,
    CloseButton,
    Modal,
    Button,
    Container
} from 'react-bootstrap';
import {NavLink, useLocation} from "react-router-dom";
import logo from "../../assets/icons/logo.svg"
import userPhoto from "../../assets/icons/user.png"
import monitoring from "../../assets/icons/navbar/Monitoring.svg"
import monitoringActive from "../../assets/icons/navbar/MonitoringActive.svg"
import report from "../../assets/icons/navbar/Report.svg"
import reportActive from "../../assets/icons/navbar/ReportActive.svg"
import tracks from "../../assets/icons/navbar/Tracks.svg"
import tracksActive from "../../assets/icons/navbar/TracksActive.svg"
import HeaderMenu from "./HeaderMenu/HeaderMenu";


function Header({logout, user, ...props}) {
    const location = useLocation();
    let [activeParams, toggleParams] = useState(false);


    return (
        <header>
            <Row className="mx-0">
                <Col className="header_block px-0" sm={12}>
                    <div className="header_item logo">
                        <img src={logo}/>
                    </div>
                    <div className="header_item user">
                        <span>{user}</span>
                        <img src={userPhoto}/>
                        <div className="profile_menu">
                            <ul>
                                <li onClick={() => toggleParams(true)}>Налаштування</li>
                                <li>Підтримка</li>
                                <li onClick={() => logout()}>Вихід</li>
                            </ul>
                        </div>
                    </div>
                    <HeaderMenu activeParams={activeParams} toggleParams={toggleParams}/>


                </Col>
            </Row>
            <hr/>
            <Row className="mx-0">
                <Col className="px-0" sm={12}>
                    <Navbar collapseOnSelect>
                        <Nav className="mr-auto">
                            <NavLink to="/">{location.pathname !== "/" ? <img src={monitoring} alt="monitoring"/> :
                                <img src={monitoringActive} alt="monitoringActive"/>}Моніторинг</NavLink>
                            <NavLink to="/track">{location.pathname !== "/track" ? <img src={tracks} alt="tracks"/> :
                                <img src={tracksActive} alt="tracksActive"/>}Треки</NavLink>
                            <NavLink to="/reports">{location.pathname !== "/reports" ?
                                <img src={report} alt="report"/> :
                                <img src={reportActive} alt="reportActive"/>}Звіти</NavLink>
                        </Nav>
                    </Navbar>
                </Col>
            </Row>
        </header>
    );
}


export default Header

