import {dozorAPI} from "../API/Api";


const SET_ACTIVE_TRACK = "SET_ACTIVE_TRACK"
const TOGGLE_ACTIVE_TRACK_LOCATION = "TOGGLE_ACTIVE_TRACK_LOCATION"
const TOGGLE_ACTIVE_TRACK_TOOLTIP = "TOGGLE_ACTIVE_TRACK_TOOLTIP"
const ALL_ACTIVE_TRACK_TOOLTIP = "ALL_ACTIVE_TRACK_TOOLTIP"
const ALL_ACTIVE_TRACK_LOCATION = "ALL_ACTIVE_TRACK_LOCATION"
const DEL_ACTIVE_TRACK = "DEL_ACTIVE_TRACK"
const GET_TRACK = "GET_TRACK"
const GET_GROUP = "GET_GROUP"
const GET_STREETS = "GET_STREETS"
const GET_LOCATION = "GET_LOCATION"
const TOGGLE_START = "TOGGLE_START"
const SET_CENTER = "SET_CENTER"
const LOGOUT_DEL_DATA = "LOGOUT_DEL_DATA"


let initialState = {
    track: [],
    group: [],
    location: [],
    activeMapTrack: [],
    isStart: false,
    streets: [],
    center: null,
    allInfo: null

}


const monitoringReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRACK: {

            if (state.allInfo !== null) {

                const result = state.allInfo.map(item2 => {
                    // отфильтровали массив 1 на наличие элементов с соответствующим id
                    const withCurrentId = action.data.filter(item1 => item1['uid'] === item2['uid']);

                    // склеили новый объект с суммами свойств
                    const item1 = withCurrentId.reduce((acc, curr) => {
                        // если в аккумуляторе нет свойства page_views, то 0, потом суммируем
                        acc['device_group_uid'] = curr['device_group_uid'];
                        acc['name'] = curr['name'];
                        acc['connected'] = curr['connected'];
                        acc['stop_time'] = curr['stop_time'];
                        acc['move_time'] = curr['move_time'];
                        acc['update_timestamp'] = curr['update_timestamp'];


                        return acc;
                    }, {});

                    return {...item2, ...item1};
                });


                return {
                    ...state,
                    allInfo: result
                }


            } else {
                return {
                    ...state,
                    track: action.data,
                    allInfo: action.data
                }
            }
        }

        case GET_GROUP: {
            if (action.data !== undefined) {
                action.data = [...action.data, {name: "без групи", uid: ""}]
                return {
                    ...state,
                    group: action.data
                }
            } else
                return {
                    ...state,
                    group: action.data
                }
        }
        case GET_LOCATION: {


            const result = state.allInfo.map(item2 => {
                // отфильтровали массив 1 на наличие элементов с соответствующим id
                const withCurrentId = action.data.filter(item1 => item1['device_uid'] === item2['uid']);

                // склеили новый объект с суммами свойств
                const item1 = withCurrentId.reduce((acc, curr) => {
                    // если в аккумуляторе нет свойства page_views, то 0, потом суммируем
                    acc['tracker_timestamp'] = curr['tracker_timestamp'];
                    acc['server_timestamp'] = curr['server_timestamp'];
                    acc['satellites'] = curr['satellites'];
                    acc['speed'] = curr['speed'];
                    acc['point'] = curr['point'];


                    return acc;
                }, {});

                return {...item2, ...item1};
            });


            return {
                ...state,
                location: action.data,
                allInfo: result
            }
        }
        case SET_ACTIVE_TRACK: {
            if (!state.activeMapTrack.map(x => x.uid).includes(action.activeTrack) && state.location.find(x=> x.device_uid === action.activeTrack)) {
                return {
                    ...state,
                    activeMapTrack: [...state.activeMapTrack, {
                        uid: action.activeTrack,
                        isLocation: action.isLocation,
                        isTooltip: null
                    }]
                }
            } else return state;
        }
        case TOGGLE_ACTIVE_TRACK_LOCATION: {
            return {
                ...state,
                activeMapTrack: state.activeMapTrack.map(item => item.uid === action.uid ? {
                    uid: item.uid,
                    isLocation: action.isLocation,
                    isTooltip: item.isTooltip
                } : item)
            }
        }
        case ALL_ACTIVE_TRACK_LOCATION: {
            return {
                ...state,
                activeMapTrack: state.activeMapTrack.map(item => item.isLocation !== action.isLocation ? {
                    uid: item.uid,
                    isLocation: action.isLocation,
                    isTooltip: item.isTooltip
                } : item)

            }
        }
        case TOGGLE_ACTIVE_TRACK_TOOLTIP: {
            return {
                ...state,
                activeMapTrack: state.activeMapTrack.map(item => item.uid === action.uid ? {
                    uid: item.uid,
                    isLocation: item.isLocation,
                    isTooltip: action.isTooltip
                } : item)

            }

        }
        case ALL_ACTIVE_TRACK_TOOLTIP: {

            return {
                ...state,
                activeMapTrack: state.activeMapTrack.map(item => item.isTooltip !== action.isTooltip ? {
                    uid: item.uid,
                    isLocation: item.isLocation,
                    isTooltip: action.isTooltip
                } : item)

            }
        }
        case DEL_ACTIVE_TRACK: {
            return {
                ...state, activeMapTrack: [...state.activeMapTrack.filter(item => item.uid !== action.payload)]
            }
        }


        case TOGGLE_START: {
            return {
                ...state,
                isStart: true
            }
        }
        case GET_STREETS: {
            action.data.uid = action.uid
            const result = state.allInfo.map(item2 => {
                if (item2.uid === action.uid) {
                    item2.street = action.data.display_name
                }
                return {...item2};
            });

            return {
                ...state,
                streets: [...state.streets, action.data],
                allInfo: result
            }
        }
        case SET_CENTER: {
            if (action.payload !== "6ef6d44a3b0425e52da3d24bd0507bc6") {
                return {
                    ...state, center: action.payload
                }
            }
            return state
        }

        case LOGOUT_DEL_DATA: {
            return {
                ...state,
                isStart: false,
                track: [],
                group: [],
                location: [],
                activeMapTrack: [],
                streets: [],
                allInfo: null,
                center: [50.270688, 30.381243],
            }
        }

        default:
            return state;
    }
}


export const setActiveTrack = (activeTrack, isLocation, isTooltip) => ({
    type: SET_ACTIVE_TRACK,
    activeTrack,
    isLocation,
    isTooltip
})
export const toggleATLocation = (uid, isLocation) => ({type: TOGGLE_ACTIVE_TRACK_LOCATION, uid, isLocation})
export const toggleATTooltip = (uid, isTooltip) => ({type: TOGGLE_ACTIVE_TRACK_TOOLTIP, uid, isTooltip})
export const allATTooltip = (isTooltip) => ({type: ALL_ACTIVE_TRACK_TOOLTIP, isTooltip})
export const allATLocation = (isLocation) => ({type: ALL_ACTIVE_TRACK_LOCATION, isLocation})
export const delActiveTrack = (activeTrack) => ({type: DEL_ACTIVE_TRACK, payload: activeTrack})
export const getTrack = (track) => ({type: GET_TRACK, data: track})
export const getGroup = (group) => ({type: GET_GROUP, data: group})
export const getLocation = (location) => ({type: GET_LOCATION, data: location})
export const getStreets = (street, uid) => ({type: GET_STREETS, data: street, uid: uid})
export const setCenter = (center) => ({type: SET_CENTER, payload: center})
export const toggleStart = () => ({type: TOGGLE_START})
export const logoutDelData = () => ({type: LOGOUT_DEL_DATA})


export const getTrackData = (key) => {
    return async (dispatch) => {
        let response = await dozorAPI.getTrack(key)
        dispatch(getTrack(response.data[0]));
        console.log("1")
    }
}
export const getGroupData = (key) => {
    return async (dispatch) => {
        let response = await dozorAPI.getGroup(key)
        dispatch(getGroup(response.data[0]));
        console.log("getGroupData " + response.data)
    }
}
export const getLocationData = (key) => {
    return async (dispatch) => {
        let response = await dozorAPI.getLocation(key)
        dispatch(getLocation(response.data[0]));
        console.log("2")


    }
}
export const getStreetsData = (point, uid) => {

    return async (dispatch) => {
        let response = await dozorAPI.getStreet(point)
        dispatch(getStreets(response.data, uid));
        console.log("3")
    }
}
export const setGroupForTrack = (key, tr, groupId) => {
    return async (dispatch) => {
        let response = await dozorAPI.setGroupForTrack(key, tr, groupId)
        console.log(response.data)
    }
}
export const setRenameGroup = (key, newName, groupId) => {
    return async (dispatch) => {
        let response = await dozorAPI.setRenameGroup(key, newName, groupId)
        console.log(response.data)
        if (response.data.status === "OK") {
            let response = await dozorAPI.getGroup(key)
            dispatch(getGroup(response.data[0]));
        }
    }
}
export const delGroup = (key, groupId) => {
    return async (dispatch) => {
        let response = await dozorAPI.delGroup(key, groupId)
        console.log(response.data)
        if (response.data.status === "OK") {
            let response = await dozorAPI.getGroup(key)
            dispatch(getGroup(response.data[0]));
        }
    }
}


export default monitoringReducer;