import "../table.css"
import React from "react";
import locationOn from "../../../../assets/icons/location-On.svg"
import locationOff from "../../../../assets/icons/toolbar-nav/Location.svg"
import tooltipOff from "../../../../assets/icons/toolbar-nav/Tooltip.svg"
import tooltipOn from "../../../../assets/icons/Tooltip ON.svg"
import connectionGreen from "../../../../assets/icons/Connection-Green.svg"
import connection from "../../../../assets/icons/toolbar-nav/Connection-Outline.svg"
import signal from "../../../../assets/icons/Signal-Red.svg";
import StopTimeFunction from "../../../HelpFunction/StopTimeFunction";


function TableBody({activeMapTrack, tr, hiddenCols, location, ...props}) {
    let trackTime = tr.tracker_timestamp ? new Date(tr.tracker_timestamp) : "Disconnect"

    let isLocation = activeMapTrack.find(x => x.uid === tr.uid) ? activeMapTrack.find(x => x.uid === tr.uid).isLocation : false
    let isTooltip = activeMapTrack.find(x => x.uid === tr.uid) ? activeMapTrack.find(x => x.uid === tr.uid).isTooltip : false

    let showLocation = isLocation ? locationOn : locationOff
    let showTooltip = isTooltip ? tooltipOn : tooltipOff
    let showConnected = tr.connected ? connectionGreen : connection


    let speedStopTime =  tr.stop_time === "" ? <td>{tr.connected ? tr.speed : "0"} км/год</td> :
        <td> <StopTimeFunction time={new Date(tr.update_timestamp)} stop_time={tr.stop_time}/></td>

    return (
        <tr key={tr.uid}>
            <td>{tr.name}</td>
            <td className={"table_body_address"}>{tr.street}</td>
            <td><img  src={showTooltip} alt={"showTooltip"} onClick={() => props.toggleATTooltip(tr.uid, !isTooltip)}/>
            </td>
            <td><img src={showLocation} alt={"showLocation"}
                     onClick={() => props.toggleATLocation(tr.uid, !isLocation)}/></td>
            <td><img src={showConnected} alt={"showConnected"}/></td>
            <td>{tr.satellites !== undefined ?

                <span title="Кількість супутників" className="table_satellites">{tr.satellites}</span> :
                <img title="Кількість супутників" src={signal} alt="signal"/>}</td>
            {!hiddenCols.map(x => x).includes("enterprise") && <td>{tr.company}</td>}
            {!hiddenCols.map(x => x).includes("subdivision") && <td>{tr.subdivision}</td>}
            {!hiddenCols.map(x => x).includes("driver") &&<td>{tr.driver}</td>}
            {!hiddenCols.map(x => x).includes("drp") && <td>{tr.DRP}</td>}
            {!hiddenCols.map(x => x).includes("transportGroup") && <td>{tr.transportGroup}</td>}
            {!hiddenCols.map(x => x).includes("model") && <td>{tr.model}</td>}
            {!hiddenCols.map(x => x).includes("trackerTime") && <td>{trackTime.toLocaleString()}</td>}
            {!hiddenCols.map(x => x).includes("hired") && <td>{tr.hired}</td>}
            {!hiddenCols.map(x => x).includes("speedStopTime") && speedStopTime}
        </tr>
    );
}

export default TableBody;