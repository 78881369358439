import React, {useState} from "react";
import '../../Toolbar.css';
import {
    Col,
    Row,
    Modal,
    Button,
    Container, InputGroup, FormControl
} from 'react-bootstrap';


function AddGroupMenu({isCreateGroupMenu, toggleCreateGroupMenu, toggleGroupMenu, toggleAddTrackers,toggleGroupUid,groupUid, renameGroup, ...props}) {
    const [name, setName] = useState(""); //назвать групу значения инпута

    let handleSearch = (e) => {
        setName(e)
    }


    return (<Modal dialogClassName={"add_group_menu"} centered show={isCreateGroupMenu}
               onHide={() => toggleCreateGroupMenu(false)}>
            <Modal.Header>
                    <Modal.Title>Створити групу</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className={"add_group_menu_body"}>
                    <Row className={"input_name_group_menu"}>
                        <Col xs={12}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Назва групи"
                                    value={name}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button className={"btn_add_to_group_group_menu"} variant="primary" onClick={()=>{
                                toggleAddTrackers(true)
                                toggleCreateGroupMenu(false)
                                renameGroup(name, groupUid)
                            }} >
                                <span>Додати трекери</span>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        <Modal.Footer>
            <Container className={"add_group_menu_body"}>
            <Row>
                <Col xs={12} className={"add_group_menu_footer_position"} >
                    <Button variant="primary" onClick={()=>{
                        toggleGroupMenu(true)
                        toggleCreateGroupMenu(false)
                    }}>
                        <span>Скасувати</span>
                    </Button>
                    <Button variant="primary" onClick={()=>{
                        toggleGroupMenu(true)
                        toggleCreateGroupMenu(false)
                        renameGroup(name, groupUid)
                    }}>
                        <span>Зберегти</span>
                    </Button>
                </Col>
            </Row>
            </Container>
        </Modal.Footer>

        </Modal>);
}


export default AddGroupMenu

