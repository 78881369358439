import React from "react";
import {connect} from "react-redux";
import Login from "./Login";
import {getAuthUserData} from "../../Redux/loginReducer";


class LoginContainer extends React.Component {


    getAuth = (login) => {
        this.props.getAuthUserData(login)
    }



    render() {
        return <Login {...this.props} getAuth={this.getAuth} />
    }
}

let mapStateToProps = (state) => {
    return {
        errorMessage: state.loginReducer.error_message,
        status: state.loginReducer.status,
    }


}

export default connect(mapStateToProps, {getAuthUserData})(LoginContainer);