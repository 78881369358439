import React, {useState} from "react";
import '../../../Toolbar.css';
import {
    Col,
    Row,
    Modal,
    Button,
    Container, InputGroup, FormControl
} from 'react-bootstrap';
import search from "../../../../../../../assets/icons/search_icon.svg";
import MenuAccordionTrack from "../MenuAccordionTrack/MenuAccordionTrack";
import SearchBlock from "../../../../../../HelpFunction/SearchBlock";


function EditGroupMenu({
                           groupUid, toggleEditGroup,
                           toggleGroupMenu, toggleAddTrackers,
                           editGroup, renameGroup, ...props
                       }) {

    const [name, setName] = useState(""); //изменить групу значения инпута
    const [showTrack, toggleShowTrack] = useState([...props.track]);


    let handleSearch = (e) => {
        setName(e)
    }


    let track = showTrack.map(tr => {
        if (groupUid === tr.device_group_uid) {

            return <MenuAccordionTrack isSetGroupForTrack={props.isSetGroupForTrack} key={tr.uid} tr={tr}
                                       isDeleteBtn={true}
            />
        } else return null
    })

    return (
        <Modal dialogClassName={"change_group_menu"} centered show={editGroup} onHide={() => toggleEditGroup(false)}>

            <Modal.Header>
                <Container>
                    <Modal.Title>Редагувати групу</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container className={"group_menu_body"}>
                    <Row>
                        <Col xs={12}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Назва групи"
                                    value={name}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button variant="primary"
                                    onClick={() => {
                                        toggleEditGroup(false)
                                        toggleAddTrackers(true)
                                    }}>
                                <span>Додати трекери</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SearchBlock showTrack={showTrack} toggleShowTrack={toggleShowTrack}
                                         fullList={props.track} placeholder={"Пошук трекера"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={"group_space"}>
                            <ul className={"menu_track_list"}>
                                {track}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"btn_tooltip_position"} xs={6}></Col>
                        <Col className={"btn_tooltip_position"} xs={6}>
                            <Button className={"btn_tooltip_menu left"} variant="primary" onClick={() => {
                                toggleEditGroup(false)
                                toggleGroupMenu(true)
                                setName("")
                            }}>
                                Скасувати
                            </Button>
                            <Button onClick={() => {
                                renameGroup(name,groupUid)
                                toggleEditGroup(false)
                                toggleGroupMenu(true)
                                setName("")
                            }} className={"btn_tooltip_menu"} variant="primary">
                                Зберегти
                            </Button></Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}


export default EditGroupMenu

