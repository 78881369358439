import React, {useEffect, useState} from "react";
import './Toolbar.css';
import {Button, InputGroup, FormControl, Col, Row, Form} from 'react-bootstrap';
import search from "../../../../assets/icons/search_icon.svg"
import clSearch from "../../../../assets/icons/X.svg"
import AccordionGroup from "./AccordionGroup/AccordionGroup";
import folder from "../../../../assets/icons/toolbar-nav/Add-folder.svg"
import clock from "../../../../assets/icons/toolbar-nav/Clock.svg"
import connection from "../../../../assets/icons/toolbar-nav/Connection-Outline.svg"
import list from "../../../../assets/icons/toolbar-nav/List.svg"
import listOn from "../../../../assets/icons/ListOn.svg"
import locationOff from "../../../../assets/icons/toolbar-nav/Location.svg"
import locationOn from "../../../../assets/icons/location-On.svg"
import menu from "../../../../assets/icons/toolbar-nav/Open-menu.svg"
import menuOn from "../../../../assets/icons/menuOn.svg"
import signal from "../../../../assets/icons/toolbar-nav/Satellite-Signal.svg"
import sortDown from "../../../../assets/icons/toolbar-nav/Sort-AZ-Down.svg"
import sortUp from "../../../../assets/icons/toolbar-nav/Sort-AZ-Up.svg"
import speedometer from "../../../../assets/icons/toolbar-nav/Speedometer.svg"
import tooltipOff from "../../../../assets/icons/toolbar-nav/Tooltip.svg"
import tooltipOn from "../../../../assets/icons/Tooltip ON.svg"
import tracking from "../../../../assets/icons/toolbar-nav/Tracking.svg"
import AccordionTrack from "./AccordionGroup/AccordionTrack/AccordionTrack";
import ChangeGroupMenu from "./ChangeGroupMenu/ChangeGroupMenu";


function Toolbar({
                     allInfoAboutTrack,
                     showAllTooltip,
                     toggleAllShowTooltip,
                     showAllLocation,
                     toggleAllShowLocation,
                     ...props
                 }) {


    const [main, setMain] = useState(false); //all checkbox
    const [open, setOpen] = useState(null); //відкрити-закрити групи
    const [displayTrack, toggleDisplayTrack] = useState(true); //відобразити-сховати групи
    const [groupMenu, toggleGroupMenu] = useState(false); //змінити групи меню


    const [foundTrack, setFoundTrack] = useState([]); //пошук по назві збіги
    const [searchValue, setSearchValue] = useState(""); //пошук по назві значення інпута
    const [openSearch, setOpenSearch] = useState(false); //пошук по назві вкл-викл пошук

    const [sortTrack, toggleSortTrack] = useState("alphabet"); //внутрений переключатель сортування
    const [sortTrackAlphabet, toggleSortTrackAlphabet] = useState(true); //сортування по назві
    const [sortTrackTime, toggleSortTrackTime] = useState(false); //сортування по часу
    const [sortTrackSpeed, toggleSortTrackSpeed] = useState(false); //сортування по часу зупинки
    const [sortTrackSatellites, toggleSortTrackSatellites] = useState(false); //сортування по часу зупинки

    const handleMain = (e) => {
        setMain(e.target.checked);
    };


    //*****сортування*****
    let sortTracker = [...props.allInfo]


    function SortArrayBySpeed() {
        let speedArray = [], stopTimeArray = [], badConnectedArray = []
        sortTracker.forEach(el => {
            if (el.stop_time === "") {
                if (el.speed === undefined || el.connected === false) {
                    badConnectedArray.push(el)
                } else {
                    speedArray.push(el)
                }

            } else stopTimeArray.push(el)
        })

        if (sortTrackSpeed) {
            speedArray.sort(function (x, y) {
                return x.speed - y.speed
            })
            stopTimeArray.sort(function (x, y) {
                return x.stop_time.localeCompare(y.stop_time);
            })
        } else {
            speedArray.sort(function (x, y) {
                return y.speed - x.speed
            })
            stopTimeArray.sort(function (x, y) {
                return y.stop_time.localeCompare(x.stop_time);
            })
        }
        return speedArray.concat(badConnectedArray, stopTimeArray);
    }


    function SortArray(x, y) {

        if (sortTrack === "alphabet") {
            if (sortTrackAlphabet) {
                return x.name.localeCompare(y.name);
            } else return y.name.localeCompare(x.name);
        } else if (sortTrack === "time") {
            if (sortTrackTime) {
                return y.update_timestamp.localeCompare(x.update_timestamp);
            } else return x.update_timestamp.localeCompare(y.update_timestamp);
        } else if (sortTrack === "satellites") {
            if (sortTrackSatellites) {
                if (x.satellites !== undefined && y.satellites !== undefined) {
                    return x.satellites - y.satellites
                } else if (x.satellites !== undefined && y.satellites !== undefined) {
                    return y.satellites - x.satellites
                }
            }
            if (x.satellites === undefined) {
                return 1
            } else if (y.satellites === undefined) {
                return -1
            }
        }
    }


    sortTracker = sortTrack === "speed" ? SortArrayBySpeed() : sortTracker.sort(SortArray)

    sortTracker.sort(SortArray)


    let showTrack = openSearch ? foundTrack : sortTracker
//**********

//*******вивід в группі чи без групи*************
    let group = props.group.map(p => <AccordionGroup key={p.uid}
                                                     setCenter={props.setCenter}
                                                     activeMapTrack={props.activeMapTrack}
                                                     onTrack={props.onTrack}
                                                     offTrack={props.offTrack}
                                                     showTrack={showTrack}
                                                     location={props.location}
                                                     streets={props.streets}
                                                     p={p}
                                                     main={main}
                                                     toggleATLocation={props.toggleATLocation}
                                                     toggleATTooltip={props.toggleATTooltip}
                                                     open={open}
    />)


    let track = showTrack.map(tr => <AccordionTrack key={tr.uid} streets={props.streets}
                                                    activeMapTrack={props.activeMapTrack}
                                                    location={props.location} onTrack={props.onTrack}
                                                    setCenter={props.setCenter}
                                                    offTrack={props.offTrack} tr={tr}
                                                    toggleATLocation={props.toggleATLocation}
                                                    toggleATTooltip={props.toggleATTooltip}
                                                    main={main}
    />)


    let showList = displayTrack ? group : <ul className={"toolbar_track_list"}>{track}</ul>
//*******


//*******search*************
    let handleSearch = (e) => {
        setSearchValue(e)
        let findTrack = props.allInfo.filter(function (item) {
            return item.name.toLowerCase().search(
                e.toLowerCase()) !== -1;
        });
        setFoundTrack(findTrack)
        toggleDisplayTrack(false)
        setOpenSearch(true)
    }
    let closeSearch = () => {
        setSearchValue("")
        setFoundTrack([])
    }
    useEffect(() => {
        searchValue === "" && setOpenSearch(false)
    }, [searchValue]);


//********************


    return (
        <div className="toolbarBlock">
            <Row className="pt-3 tb_menu_block ">
                <div className="tb_menu_btn">
                    {(open === null || open === false) ? <img title="Розгорнути/згорнути групи" onClick={() => {
                            if (open === null || open === false) {
                                setOpen(true)
                            }
                        }} src={menu} alt={"menu"}/> :
                        <img title="Розгорнути/згорнути групи" onClick={() => {
                            if (open === null || open === true) {
                                setOpen(false)
                            }
                        }} src={menuOn} alt={"menu"}/>}
                    {displayTrack ? <img onClick={() => toggleDisplayTrack(!displayTrack)}
                                         title="Відобразити список трекерів без груп"
                                         src={list} alt={"list"}/> :
                        <img onClick={() => toggleDisplayTrack(!displayTrack)}
                             title="Відобразити список трекерів без груп"
                             src={listOn} alt={"list"}/>}
                    <img title="Додати/змінити групу" onClick={() => toggleGroupMenu(true)} src={folder}
                         alt={folder}/>
                </div>
                <div className="tb_menu_icon">
                    {sortTrackAlphabet ?
                        <img onClick={() => {
                            toggleSortTrackAlphabet(false)
                            toggleSortTrack("alphabet")
                        }
                        } title="Сортування трекерів по алфавіту"
                             src={sortDown} alt={"sort"}/> :
                        <img onClick={() => {
                            toggleSortTrackAlphabet(true)
                            toggleSortTrack("alphabet")
                        }
                        } title="Сортування трекерів по алфавіту" src={sortUp}
                             alt={"sort"}/>}
                    <img title="Час трекера" src={clock} alt={clock} onClick={() => {
                        toggleSortTrackTime(!sortTrackTime)
                        toggleSortTrack("time")
                    }
                    }/>
                    <img title="Швидкість/час зупинки" src={speedometer} alt={speedometer}
                         onClick={() => {
                             toggleSortTrack("speed")
                             toggleSortTrackSpeed(!sortTrackSpeed)
                         }}/>
                    {showAllLocation ?
                        <img title="Cховати на карті" onClick={() => {
                            toggleAllShowLocation(false)
                            props.allATLocation(false)
                        }} src={locationOn}
                             alt="locationOn"/> :
                        <img title="Відобразити на карті" onClick={() => {
                            toggleAllShowLocation(true)
                            props.allATLocation(true)
                        }} src={locationOff}
                             alt="locationOff"/>}
                    {showAllTooltip ? <img title="Cховати тултип" onClick={() => {
                            toggleAllShowTooltip(false)
                            props.allATTooltip(false)
                        }} src={tooltipOn} alt="tooltip"/> :
                        <img title="Відобразити тултип" onClick={() => {
                            toggleAllShowTooltip(true)
                            props.allATTooltip(true)
                        }} src={tooltipOff}
                             alt="tooltip"/>}

                    <img title="Звязок з сервером" src={connection} alt={connection}/>
                    <img title="Кількість супутників" src={signal} alt={signal} onClick={() => {
                        toggleSortTrackSatellites(!sortTrackSatellites)
                        toggleSortTrack("satellites")
                    }}/>
                    <img title="Відобразити історію руху" src={tracking} alt={tracking}/>
                </div>
            </Row>
            <Row className="pt-3 input_block">
                <Col lg="1" className={'accordion_all_checkbox'}>
                    <Form.Check bg="background: #5383FF;"
                                checked={main}
                                onChange={handleMain}
                                type="checkbox"
                                id="main"
                    /></Col>
                <Col lg="10">
                    <InputGroup>
                        <FormControl
                            placeholder="Пошук трекера"
                            value={searchValue}
                            onChange={(e) => handleSearch(e.target.value)}

                        />
                        {openSearch ?
                            <Button className={"input_btn_search"} onClick={() => closeSearch()}>
                                <img src={clSearch} alt="closeSearch"/>
                            </Button> :
                            <Button className={"input_btn_search"}>
                                <img src={search} alt="search"/>
                            </Button>
                        }
                    </InputGroup>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col className="mx-auto scroll" lg="11">
                    {showList}


                </Col>
            </Row>


            <ChangeGroupMenu renameGroup={props.renameGroup} deleteGroup={props.deleteGroup}
                             clearSelectTrack={props.clearSelectTrack} addSelectTrack={props.addSelectTrack}
                             delSelectTrack={props.delSelectTrack} toggleGroupMenu={toggleGroupMenu}
                             isSetGroupForTrack={props.isSetGroupForTrack}
                             groupMenu={groupMenu} group={props.group} track={props.track}
                             selectTrack={props.selectTrack}
                             setGroupTrack={props.setGroupTrack}/>
        </div>
    );
}

export default Toolbar;
