import React, {useEffect, useState} from "react";
import './map.css'
import {MapContainer, ZoomControl, Pane, useMapEvents, Popup, Tooltip, Marker} from "react-leaflet";
import polygon from "../../../../assets/icons/Polygon.svg"
import MarkerClusterGroup from "react-leaflet-markercluster";
import L, {Icon} from 'leaflet'
import MapMarker from "./Marker/MapMarker";
import LayersControlMap from "./LayersControl/LayerControl";
import LeafletgeoSearch from "./SearchControl/LeafletgeoSearch";
import SliderZoom from "./SliderControl/SliderZoom";
import EyeControl from "./EyeControl/EyeControl";
import MarkerOutMap from "./markerOutMap/markerOutMap";
import mrIcon from "../../../../assets/icons/map-marker.png";



function MapBlock({setCenter ,center, activeMapTrack, location, ...props}) {
    const [zoomLevel, setZoomLevel] = useState(6);
    const [map, setMap] = useState(null);



    L.Icon.Default.imagePath = "/";




    function handleOnFlyTo(centerMap) {
        if (centerMap!==null) {map.flyTo([centerMap[1], centerMap[0]],16);
        }
    }
    function lurkingTooltip() {
        if (map) {map.flyToBounds(props.setBoundsTooltip());
        }
    }



    useEffect(() => {
        props.tooltipsLocation.length > 0  && lurkingTooltip()


    }, [props.tooltipsLocation]);

    useEffect(() => {


        map!==null && handleOnFlyTo(center)
    }, [center]);




    let activeTrack = props.allInfo.map(tr => {
        if (activeMapTrack.find(x => x.uid === tr.uid && x.isLocation === true)) {
            return <MapMarker key={tr.uid} tr={tr} activeMapTrack={activeMapTrack} map={map}
                              toggleATTooltip={props.toggleATTooltip}
                              tooltipCheckbox={props.tooltipCheckbox}
                              tooltipsLocation={props.tooltipsLocation}/>
        } else return null
    })



    let outMapTrack = props.allInfo.map(tr => {
        if (activeMapTrack.find(x => x.uid === tr.uid && x.isLocation === true)) {
            return <MarkerOutMap zoomLevel={zoomLevel} map={map} key={tr.uid}  tr={tr} handleOnFlyTo={handleOnFlyTo}/>
        } else return null
    })

    return (
        <div className="polPosition">

            <span className="pol" onClick={() => {
                  props.isActiveTable(!props.activeTable)
            }}><img src={polygon} alt={"polygon"}/></span>
            <MapContainer  maxZoom={16} minZoom={3}  center={[48.573, 31.280]} zoom={zoomLevel} zoomControl={false} whenCreated={m => setMap(m)}>
                <LeafletgeoSearch
                    showMarker={true}
                    showPopup={true}
                    popupFormat={({query, result}) => result.label}
                    maxMarkers={1}
                    retainZoomLevel={false}
                    animateZoom={false}
                    autoClose={true}
                    searchLabel={"Enter address, please"}
                    keepResult={true}
                />

                <EyeControl isTooltipCheckbox={props.isTooltipCheckbox} tooltipCheckbox={props.tooltipCheckbox}/>
                <LayersControlMap/>
                <ZoomControl className="zoom_control" zoomInText="" zoomOutText=""/>
                <SliderZoom zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
                <Pane name="track" style={{zIndex: 900}}>
                    {/*<MarkerClusterGroup   disableClusteringAtZoom={"15"}>*/}
                        {activeTrack}
                    {/*</MarkerClusterGroup>*/}
                </Pane>
                {outMapTrack}

            </MapContainer>
        </div>
    );
}

export default MapBlock;
