import React, {useState} from 'react';

import '../map.css'
import TooltipMenu from "./TooltipMenu/TooltipMenu";

function EyeControl(props) {
    const [activeTooltipMenu, toggleTooltipMenu] = useState(false);

    return <div className="leaflet-top leaflet-left">
        <button className={"eye_control leaflet-control leaflet-bar"} onClick={() => {
            toggleTooltipMenu(true)
        }}/>
        <TooltipMenu isTooltipCheckbox={props.isTooltipCheckbox} tooltipCheckbox={props.tooltipCheckbox} activeTooltipMenu={activeTooltipMenu} toggleTooltipMenu={toggleTooltipMenu}/>
          </div>
}

export default EyeControl;

